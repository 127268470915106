import { useEffect, memo } from 'react'
import { cardViewData } from '../../model/components/cardViewData'
import useToast from '../../hooks/useToast'
import styled from 'styled-components'

//State Logic
import useFormValidation from './useFormValidation'
//TO REMOVE: import useFormMessage from './useFormMessage'
import useFormModal from './useFormModal'

//Importing Form Logic
import useFormActions from './useFormActions'

//Importing the Subcomponents
import CardViewTitle from './CardViewTitle'
import CardViewForm from './CardViewForm'
import MessageBar from '../toast/MessageBar'
import { PopUp } from '../modal/PopUp'

import { useMessage } from '../pageView/Page'

const CardViewWrapper = styled.div`
  display: flex;
  gap: 1rem;
  max-width: ${({ width }) => width ? width : ''}
`
//Importing Local State

const MainForm = styled.div`
  display: block;
  //This ensures the DIV fills all the available space
  flex-grow: 1;
`

const CardView = memo(({ type, options, dispatch }) => {

  //console.log(' [Card View] type: ', type)
  //console.log(' [Card View] options: ', options)
  
  //State used to manage the MessageBar at the Top of the Form
  //const { message, setMessage } = useFormMessage()
  const [message, setMessage] = useMessage((s) => s)

  //Managing the Confirmation Dialog
  const { showModal, setShowModal, modalConfig } = useFormModal()
  //State to manage the validation state
  const { validated, setValidated } = useFormValidation()

  useEffect(() => {
    // Function to run when component unmounts
    return () => {
      //Ensure Validation is turned off & Message is off
      setValidated(false);
      setMessage(false);
      setShowModal(false);
    };
  }, []
  )

  //Config to read the data from the Model
  let config = cardViewData[type]

  //Getting the State
  const mode = options.mode

  //Hook to show the Toast Message
  const { ToastComponent } = useToast();


  const { handleFormChange, handleActions } = useFormActions(mode, config)

  //console.log('re-rendering CardView')
  return (
    <CardViewWrapper width={config.width}>
      <MainForm>
        {message.show && <MessageBar title={message.title} message={message.message} type={message.type} setMessage={setMessage} action={message.action || undefined} handleAction={handleFormChange} />}
        {!config?.noCardTitle && <CardViewTitle config={config} options={options} handleActions={handleActions} />}
        <CardViewForm config={config} options={options} dispatch={dispatch}
          handleActions={handleActions}
          validated={validated}
        />
        {ToastComponent}
        {config.onDeleteConfirm && <PopUp show={showModal} setShow={setShowModal} config={modalConfig} setAction={handleActions} />}
      </MainForm>
    </CardViewWrapper>
  );
})

export default CardView