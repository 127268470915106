export const sidePanelData = {
  invoiceEditPanel: {
    entity: 'invoice',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "invoicePDF",
          width: "600px",
        }
      },
      {
        meta: {
          type: "form",
          name: "invoiceOptions",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            control: {
              id: "optionsModal",
              type: "text",
              label: "invoice.form.options",
              placeholder: "invoice.form.options.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "invoice.form.currency.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "currency",
              },
              {
                type: "text",
                text: " - ",
              },
              {
                type: "label",
                text: "invoice.form.language.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "language",
              },

            ],
            modalGroups: 'invoiceOptions'
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.design.missing.message",
          emptyField: "currency",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.options",
                },
              ],
            },
          ],
        },
      },
      {
        meta: {
          type: "form",
          name: "invoiceDesign",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "designModal",
              type: "text",
              label: "invoice.form.design",
              placeholder: "invoice.form.design.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "invoice.form.font.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "invoiceFont",
              },
              {
                type: "text",
                text: " - ",
              },
              {
                type: "label",
                text: "invoice.form.layout.short",
              },
              {
                type: "field",
                field: "layoutName",
              },
            ],
            modalGroups: 'invoiceDesign',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.design.missing.message",
          emptyField: "invoiceFont",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.design",
                },
              ],
            },
          ],
        },
      },
      {
        meta: {
          type: "modalSection",
          name: "invoicePayments",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "paymentModal",
              type: "text",
              label: "invoice.form.payment",
              placeholder: "invoice.form.payment.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "Payments:",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "numPayments",
              }
            ],
            modalGroups: 'invoicePayments',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.payments.missing.message",
          emptyField: "invoiceFont",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.payment",
                  tag:"numPayments"
                },
              ],
            },
          ],
        },
      },
    ],
  },
  invoiceViewPanel: {
    entity: 'invoice',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "invoicePDF",
          width: "600px",
        }
      }
    ],
  },
  creditNoteViewPanel: {
    entity: 'creditNote',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "creditNotePDF",
          width: "600px",
        }
      }
    ],
  },
  stockShipmentEditPanel: {
    entity: 'stockShipment',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "stockShipmentPDF",
          width: "600px",
        }
      },
      {
        meta: {
          type: "form",
          name: "stockShipmentDesign",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "designModal",
              type: "text",
              label: "inventory.shipment.form.design",
              placeholder: "inventory.shipment.form.design.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "inventory.shipment.form.font.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "font",
              },
              {
                type: "text",
                text: " - ",
              },
              {
                type: "label",
                text: "inventory.shipment.form.layout.short",
              },
              {
                type: "field",
                field: "layoutName",
              },
            ],
            modalGroups: 'inventoryShipmentDesign',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "inventory.shipment.form.font.missing.message",
          emptyField: "font",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "inventory.shipment.form.font.edit.label",
                },
              ],
            },
          ],
        },
      },
      {
        meta: {
          type: "modalSection",
          name: "inventory.shipment.reservation", 
          width: "600px",
          modalPadding: "0rem 1rem 1rem", 
          condition: { mode: 'edit' }
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "reservationsModal",
              type: "text",
              label: "inventory.shipment.form.reservations",
              placeholder: "inventory.shipment.form.reservations.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "Reservations:",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "totalReservations",
              }
            ],
            modalGroups: 'inventoryShipmentReservations',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "inventory.shipment.form.reservations.missing.message",
          emptyField: "totalReservedProducts",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "inventory.shipment.form.reservations",
                  tag:"totalReservedProducts"
                },
              ],
            },
          ],
        },
      },
    ],
  },
  stockShipmentViewPanel: {
    entity: 'stockShipment',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "stockShipmentPDF",
          width: "600px",
        }
      }
    ],
  },
};
