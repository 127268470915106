import PropTypes from 'prop-types'
import useTranslation from "../../hooks/useTranslation"
import styled from "styled-components"
import useFormatting from "../../hooks/useFormatting"
import { TableDecorator } from "../table/Table"
import useLocale from "../../hooks/useLocale"

const BooleanSpan = styled.span`
    color: ${({ color, theme }) => color === 'true' ? theme.badge.true : theme.badge.false};
    background-color: ${({ theme }) => theme.badge.bg};
    padding: 0px 5px;
    border-radius: 10px;
    font-weight: 700;
    font-size: var(--font-XXS);
    font-family: "Prompt", sans-serif;
`

const AmountSpan = styled.span`
    color: ${({ color, theme }) => color === 'true' ? theme.font.color : theme.badge.false};
`

const DisplayElement = ({ string, type = '', item, col, prefix = null }) => {
    const { t } = useTranslation()
    const { formatString } = useFormatting()
    const { toNumber } = useLocale()

    let result = formatString(string, type, item, col)
    if (prefix) result = t(prefix) + result

    const renderBoolean = () => (
        <BooleanSpan color={item?.[col?.id] ? 'true' : 'false'}>
            {result}
        </BooleanSpan>
    )

    const renderAmount = () => {
        const isPositive = toNumber(result) >= 0
        return (
            <AmountSpan color={isPositive ? 'true' : 'false'}>
                {result}
                {item && col?.decorator && <TableDecorator>{item[col.decorator]}</TableDecorator>}
            </AmountSpan>
        )
    }

    const renderDefault = () => <span>{result}</span>

    switch (type) {
        case 'boolean':
            return renderBoolean()
        case 'amount':
            return renderAmount()
        default:
            return renderDefault()
    }
}

export default DisplayElement
