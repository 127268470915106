import React, { useEffect, memo } from 'react';
import processDefaults from '../../utils/defaults/processDefaults'

//Get current Country from Profile and use Valtio to read a snapshot
import useProfile from "../../hooks/useProfile";
import { snapshot } from "valtio";
import useSectionState from '../../hooks/useSectionState';

//Importing UI Components
import { Form } from '../form/FormComponents'
import CardViewFormSection from './CardViewFormSection'
import CardViewFormLines from './CardViewFormLines';
import CardViewFormTotals from './CardViewFormTotals';
import MobilePanel from '../mobilePanel/MobilePanel';

import runFieldsLogic from '../../../../common/logic/runFieldsLogic';

import { useStore } from '../pageView/Page';

const CardViewForm = memo(({ config, options, dispatch, handleActions, validated }) => {
    let sections = config.sections
    const entity = config.entity
    let processedData

    const { pState } = useProfile()
    let profile = snapshot(pState)
    const [state, setState] = useStore((s) => s)

    const mode = options.mode
    const load = options.load

    useEffect(
        () => {
            //ON NEW TRIGGER: ensure defaults are added to Data array and field logic is executed.
            if (!load) {
                if (!state.defaulted) {
//                    console.log('Profile is: ', profile)
                    let updatedProfile = {}
                    updatedProfile = { ...profile }
                    //Just injects the correct nSerie if available
                    if (state?.settings?.nSeries) {
                        updatedProfile.profile ={}
                        updatedProfile.profile.nSeries = state.settings.nSeries                    
                    }
                    const defaultData = processDefaults(config.sections, updatedProfile, config.defaultData)
                    processedData = runFieldsLogic({ ...defaultData, ...state }, state.entity, config.fieldsLogic)
                    setState({ ...processedData })
                }
            }

        }, []
    )

    //Getting the State
    useEffect(() => {
        //console.log('State: ', state)
    }, [state]
    )

    const { expanded, toggleExpanded } = useSectionState(config, options)

    

    const handleSubmit = async (e) => {
        console.log('Submit is active')
        e.preventDefault()
        const actionResponse = await handleActions({ action: 'save' })
    }


    const localDispatch = async (action, data) => {
        if (action === 'saveNContinue' || 'save') {
            console.log('Sending Action Off!')
            const actionResponse = await handleActions({ action: 'save' })
            console.log('There was a Local response', actionResponse)
            dispatch(action, data)
        } else {
            dispatch(action, data)
        }
    }

    //Configure the form depending on the existence of SectionNew
    if (!config.sectionsNew) { config.sectionsNew = config.sections }
    if (mode === 'edit' || mode === 'confirm') { sections = config.sections } else {
        sections = config.sectionsNew
    }

    // Prevent submission of Enter except in textareas
    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.tagName.toLowerCase() !== 'textarea') {
            e.preventDefault()
            //This dispatches all other buttons that the Submit Button which is prevented.
            if (e.target.name && e.target.name !== 'submit') {
                //dispatch(e.target.name)
            }
            if (e.target.name === 'submit') {
                handleSubmit(e)
            }
        }
    }

    useEffect(() => {
        //Updating the time stamp to ensure PDF is refreshed
        setState({ savedOn: new Date() })
    }, [])

    //console.log('re-rendering CardViewForm')
    return (
        <>
            <Form id={config.entity} autoComplete='false' name={config.entity} onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
                <input name="hidden" type="text" style={{ display: 'none' }} autoComplete='false' />
                <Form.Shell name="Form-Shell" width={config.width}>
                    {sections &&
                        sections.map((section, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {section.meta.type === 'form' ?
                                        <CardViewFormSection
                                            expanded={expanded[section.meta.name]}
                                            toggleExpanded={toggleExpanded}
                                            section={section}
                                            entity={entity}
                                            key={`${section.meta.name}`}
                                            dispatch={localDispatch}
                                            validated={validated}
                                            useStore={useStore}
                                            options={options}
                                        /> : section.meta.type === 'lines' ?
                                            <CardViewFormLines
                                                expanded={expanded[section.meta.name]}
                                                toggleExpanded={toggleExpanded}
                                                section={section}
                                                entity={entity}
                                                linesArray={section.meta.linesArray}
                                                key={`${section.meta.name}`}
                                                dispatch={dispatch}
                                                validated={validated}
                                                useStore={useStore}
                                                options={options}
                                            /> : section.meta.type === 'totals' ?
                                                <CardViewFormTotals
                                                    section={section}
                                                    tData={state}
                                                    key={`${section.meta.name}`}
                                                /> : <></>}
                                </React.Fragment>
                            )
                        }
                        )}

                </Form.Shell>
            </Form>
            {
                config.mobilePanel && <MobilePanel validated={validated}
                    config={config.mobilePanel} useStore={useStore}></MobilePanel>
            }
        </>
    );


})

export default CardViewForm
