import { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import Modal from "../modal/Modal";
import useTranslation from "../../hooks/useTranslation";
import RadioGroupSelector from "./RadioGroupSelector";
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';
import { TextControl } from "../generic/Core";

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;

const ReadOnlyDisplay = styled(TextControl)`
  flex: 1;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  cursor: default;
  
  &:focus {
    outline: none;
  }
`;

const SelectButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.btn.primary };
  color: white;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.btn.active.primary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const RadioModal = ({
  data,
  valueName,
  isVisible,
  setIsVisible,
  onSelect,
  entity,
  modalTitle,
  modalOpacity,
  options,
  menuItemField = 'name',
  radioGroups,
  persistValue,
  testId
}) => {
  const { t } = useTranslation();
  const { getMe } = useApi();
  const [selectedValues, setSelectedValues] = useState({});
  const focusRef = useRef(null);

  useEffect(() => {
    focusRef.current?.focus();
  }, []);

  // Parse valueName into selectedValues when persistValue is true
  useEffect(() => {
    if (persistValue && valueName && isVisible) {
      // Split the location code (e.g., "W1-H1-R1-L1-P1") into parts
      const parts = valueName.split('-');
      const newSelectedValues = {};

      // Map parts to their respective groups
      radioGroups.forEach((group, index) => {
        if (parts[index]) {
          // Remove the prefix (W, H, R, etc.) to get the value
          const value = parts[index];
          newSelectedValues[group.id] = value;

          // Handle subRadio groups if they exist
          if (group.subRadio && parts.length > index + 1) {
            group.subRadio.forEach((subGroup, subIndex) => {
              const subPart = parts[index + 1 + subIndex];
              if (subPart) {
                const subValue = subPart;
                newSelectedValues[subGroup.id] = subValue;
              }
            });
          }
        }
      });

      setSelectedValues(newSelectedValues);
    }
  }, [persistValue, valueName, isVisible]);

  // Function to recursively clear dependent groups
  const clearDependentGroups = (parentId, values) => {
    radioGroups.forEach(group => {
      if (group.dependsOn === parentId) {
        // Clear this group's value
        delete values[group.id];
        
        // Clear this group's subgroups
        if (group.subRadio) {
          group.subRadio.forEach(sub => {
            delete values[sub.id];
          });
        }
        
        // Recursively clear groups that depend on this group
        clearDependentGroups(group.id, values);
      }
    });
  };

  // Handle selection for any group
  const handleGroupSelection = (value, groupId, subGroupId = null) => {
    const updatedValues = { ...selectedValues };
    const id = subGroupId || groupId;
    
    // If this is a main group, clear all dependent groups recursively
    if (!subGroupId) {
      clearDependentGroups(groupId, updatedValues);
    }
    
    // Set the new value
    updatedValues[id] = value;
    
    setSelectedValues(updatedValues);
  };

  // Reset selections when modal is opened (only if not persisting value)
  useEffect(() => {
    if (isVisible && !persistValue) {
      setSelectedValues({});
    }
  }, [isVisible, persistValue]);

  // Get required filter values for a group
  const getFilterValues = (group) => {
    if (!Array.isArray(group.filter)) return {};
    
    return group.filter.reduce((acc, filterConfig) => {
      if (typeof filterConfig === 'object') {
        acc[filterConfig.param] = selectedValues[filterConfig.groupId];
      }
      return acc;
    }, {});
  };

  const renderRadioGroup = (group, idx) => {
    const parentId = group.dependsOn;
    const parentValue = parentId ? selectedValues[parentId] : undefined;

    // Don't render dependent groups until parent is selected
    if (parentId && !parentValue) {
      return null;
    }

    // Get only the filter values this group needs
    const filterValues = getFilterValues(group);

    return (
      <RadioGroupSelector 
        key={idx}
        entity={group.entity} 
        filter={group.filter}
        valueField={group.valueField || 'id'} 
        labelField={group.labelField || 'name'} 
        icon={group.icon}
        columns={group.columns || 4}
        parentValue={parentValue}
        value={selectedValues[group.id]}
        onChange={(value, subGroupId) => handleGroupSelection(value, group.id, subGroupId)}
        filterValues={filterValues}
        subRadio={group.subRadio}
        title={group.title || group.id}
        t={t}
        selectedValues={selectedValues}
        testId={`${group.id}`}
      />
    );
  };

  // Format selected values for display
  const formattedSelection = useMemo(() => {
    return radioGroups
      .map(group => {
        const mainValue = selectedValues[group.id];
        const subValues = group.subRadio?.map(subGroup => 
          selectedValues[subGroup.id]
        ).filter(Boolean);
        
        return [mainValue, ...(subValues || [])].filter(Boolean);
      })
      .flat()
      .join('-');
  }, [selectedValues, radioGroups]);

  // Check if the formatted value exists in the API
  const { data: validationData, isLoading: isValidating } = useQuery(
    ['validate', entity, formattedSelection],
    () => getMe(`${entity}/${formattedSelection}`),
    {
      enabled: Boolean(entity && formattedSelection),
      staleTime: 30000,
    }
  );

  const handleSelect = () => {
    if (validationData?.data && onSelect) {
      onSelect(validationData.data);
      setIsVisible(false);
    }
  };

  return (
    <Modal
      setShow={setIsVisible}
      show={isVisible}
      mode={"fullscreen"}
      className={"fullscreen"}
      minHeight={"35rem"}
      opacity={modalOpacity}
    >
      <Modal.Header setShow={setIsVisible}>
        <h3 style={{ margin: "0px" }}>{t(modalTitle)}</h3>
      </Modal.Header>
      <Modal.Body>
        <InputContainer >
          <ReadOnlyDisplay
            type="text"
            readOnly
            value={formattedSelection}
            placeholder={t('inventory.adjustment.form.location.modal.placeholder')}            
          />
          {validationData?.data && (
            <SelectButton onClick={handleSelect} data-testid={`${entity}.select`}>
              {t('inventory.adjustment.form.location.modal.select.button')}
            </SelectButton>
          )}
        </InputContainer>
        {radioGroups.map((group, idx) => renderRadioGroup(group, idx))}
      </Modal.Body>
    </Modal>
  );
};

export default RadioModal;
