import { appListData } from '../../model/components/appListData'
import styled from 'styled-components'
import useTranslation from '../../hooks/useTranslation'
import CardTile from '../generic/Cards'

import MobileNavBar from '../mobileNav/MobileNavBar'

const Block = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const CardListTitle = styled.h1`
    margin-bottom: 1.25rem;
`

const CardList = () => {
    const data = appListData
    const { t } = useTranslation()
    return (
        <>
            <CardListTitle name='CardListTitle' data-testid='home.apps'>{t('app.list.title')}</CardListTitle>
            <Block>
                {data.map(
                    (item, i) => {
                        return <CardTile key={i} data={item} />
                    }
                )}
            </Block>
            <MobileNavBar />
        </>
    )
}

export default CardList