import { 
    Icon_Apps, Icon_Back, Icon_Boxes, Icon_Cart,Icon_Gear,Icon_Help,Icon_Home, Icon_Message, Icon_Org, Icon_Paper,Icon_People, Icon_Invoice, 
    Icon_Contacts,
    Icon_StockMovement,
    Icon_StockAdjustment,
    Icon_Bank,
    Icon_Arrow_Left 
} from '../../styles/icons'

export const mobileNavBarData = {
    home: [
        {
            title: 'home.menu.apps',
            icon: Icon_Home,
            path: '/home/apps'
        },
        {
            title: 'home.menu.organizations',
            icon: Icon_Org,
            path: '/home/organization'
        },
        {
            title: 'home.menu.users',
            icon: Icon_People,
            path: '/home/user'
        },
        {
            title: 'home.menu.settings',
            icon: Icon_Gear,
            path: '/home/settings'
        }
    ],
    sales: [
        {
            title: 'sales.menu.home',
            path: '/sales',
            icon: Icon_Home
        },
        {
            title: 'sales.menu.invoices',
            path: '/sales/invoice',
            icon: Icon_Invoice,
        },
        {
            title: 'sales.menu.contacts',
            path: '/sales/contact',
            icon: Icon_People,
        },
        {
            title: 'sales.menu.products',
            path: '/sales/product',
            icon: Icon_Boxes,
        }
    ],
    inventory: [
        {
            title: 'all.menu.home',
            path: '/inventory',
            icon: Icon_Home
        },
        {
            title: 'inventory.menu.products',
            path: '/inventory/product',
            icon: Icon_Boxes,
        },
        {
            title: 'inventory.menu.adjustments',
            path: '/inventory/adjustment',
            icon: Icon_StockAdjustment,
        },
        {
            title: 'inventory.menu.movements',
            path: '/inventory/movement',
            icon: Icon_StockMovement,
        }
    ],
    purchases: [
        {
            title: 'all.menu.home',
            path: '/purchases',
            icon: Icon_Home
        },
        {
            title: 'purchases.menu.contacts',
            path: '/purchases/contact',
            icon: Icon_Contacts,
        }
    ],
    crm: [
        {
            title: 'crm.menu01',
            path: '/crm',
            icon: Icon_Home
        },
        {
            title: 'crm.menu02',
            path: '/crm/customer',
            icon: Icon_People,
        },
        {
            title: 'crm.menu03',
            path: '/crm/contact',
            icon: Icon_People,
        },
        {
            title: 'crm.menu04',
            path: '/crm/product',
            icon: Icon_Boxes,
        }
    ],
    service: [
        {
            title: 'service.menu01',
            path: '/service',
            icon: Icon_Home
        },
        {
            title: 'service.menu02',
            path: '/crm/customer',
            icon: Icon_People,
        },
        {
            title: 'service.menu04',
            path: '/crm/product',
            icon: Icon_Cart,
        },
        {
            title: 'service.menu00',
            path: '/home',
            icon: Icon_Back
        },
    ],
    bank: [
        {
            title: 'all.menu.home',
            path: '/bank',
            icon: Icon_Home
        },
        {
            title: 'bank.menu.accounts',
            path: '/bank/account',
            icon: Icon_Bank
        },
        {
            title: 'bank.menu04',
            path: '/bank',
            icon: Icon_Cart,
        },
        {
            title: 'bank.menu00',
            path: '/home',
            icon: Icon_Back
        },
    ]
}