import { useEffect, useState, useRef } from "react";
import { Icon_Clear, Icon_Upload, Icon_CheckMark } from "../../styles/icons";
import styled from "styled-components";
import { Button } from "../generic/Core";

const DragContainer = styled.section`
    border: 1px solid var(--border-color);
    border-radius: 8px;
`

const DocumentUploader = styled.div`  
    border: 2px dashed ;
    border-color: ${({ theme }) => theme.btn.primary};
    background-color: ${(props) => (props.isDragOver ? props.theme.form.panelBg : props.theme.pdf.background)};
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 1rem;
    cursor: pointer;
    z-index: 100;

    &.active {
      border-color: #6dc24b;
    } 
  
`
const HiddenInput = styled.input`
    display: none;
`

const UploadInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.font.color};

    svg {
      font-size: 36px;
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
    
`

const FileList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`

const FileListContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`

const FileItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
`

const FileInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    p {
      margin: 0;
      font-size: 14px;
    }
`

const FileActions = styled.div`
    cursor: pointer;
    svg {
      font-size: 18px;
      color: #888;
    }
    &:hover {
      svg {
        color: #d44;
      }
    }
`
const FileSuccess = styled.div`
    display: flex;
    align-items: center;
    color: #6dc24b;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
`

const DragNDrop = ({ onFilesSelected, width, height, }) => {
    const [files, setFiles] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);

    const fileInputRef = useRef();

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver('true');
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver('true');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        const target = e.target;

        setIsDragOver('false');

    };


    // Function to trigger file input click
    const handleClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    useEffect(() => {
        onFilesSelected(files);
    }, [files, onFilesSelected]);

    return (
        <DragContainer style={{ width: width, height: height }}>
            <DocumentUploader
                className={` ${files.length > 0 ? "upload-box active" : "upload-box"
                    }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                isdragover={isDragOver}
            >
                <>
                    <UploadInfo>
                        {Icon_Upload}
                        <div>
                            <p>Upload your logo</p>
                            <p>Limit 10MB per file. </p>
                            <p>Supported files: PNG, JPG</p>
                        </div>
                    </UploadInfo>
                    <HiddenInput
                        type="file"
                        hidden
                        id="browse"
                        onChange={handleFileChange}
                        accept=".png,.jpg"
                        multiple
                        ref={fileInputRef}
                        data-testid='upload.button.hidden'
                    />
                    <Button $variant='primary' onClick={handleClick} data-testid='upload.button'>
                        Upload
                    </Button>
                </>

                {files.length > 0 && (
                    <FileList>
                        <FileListContainer>
                            {files.map((file, index) => (
                                <FileItem key={index}>
                                    <FileInfo>
                                        <p>{file.name}</p>
                                        {/* <p>{file.type}</p> */}
                                    </FileInfo>
                                    <FileActions>
                                        <div onClick={() => handleRemoveFile(index)} >
                                            {Icon_Clear}
                                        </div>
                                    </FileActions>
                                </FileItem>
                            ))}
                        </FileListContainer>
                    </FileList>
                )}

                {files.length > 0 && (
                    <FileSuccess>
                        {Icon_CheckMark}
                        <p>{files.length} file(s) selected</p>
                    </FileSuccess>
                )}
            </DocumentUploader>
        </DragContainer>
    );
};

export default DragNDrop;