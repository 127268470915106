const emptyArray = (condition, data) => {
    //console.log('running emptyArray')

    let validationResult

    const {type, array} = condition
    if (type === 'array') {
        const arraySize = (data?.[array] || []).length;
        if (arraySize === 0 || arraySize === undefined) {
            validationResult = {valid: true}
        } else {
            validationResult = {valid: false}
        }
        return validationResult
    } 
    
    return({valid: false})

}

export default emptyArray