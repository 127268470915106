import useTranslation from "../../hooks/useTranslation";
import { ListHeader, ListCol, ListRow } from "./MultiList";
import Footer from "../footer/Footer";
import Action from "./Action";


const getTabById = (id, tabs) => {
    return tabs.find(tab => tab.id === id) || null;
};

function removeReservedItems(data, reservations) {
    // Check if data or reservations is undefined or null
    if (!data || !Array.isArray(data)) {
        return []; // Return empty array if data is not a valid array
    }
    
    if (!reservations || !Array.isArray(reservations)) {
        return data; // Return original data if reservations is not a valid array
    }
    
    // Create a Set of reservation IDs for fast lookup
    const reservationIds = new Set(reservations.map(res => res.reservationId));
  
    // Filter the data array to exclude items with matching reservation IDs
    return data.filter(item => !reservationIds.has(item.reservationId));
}

const ReservationList = ({ id, data = [], reservations = [], section = {}, useStore}) => { 
    const { t, m} = useTranslation()
    const entity = section?.entity || ''

    const tab = getTabById(id, section?.tabs || [])

    const emptyMessage = tab?.emptyMessage || 'No data available'
    const path = tab?.nav?.path
    const pathId = tab?.nav?.pathId


    const filter = tab?.filter || {};
    const description = tab?.description || null;
    const col = tab?.columns || []
    const action = tab?.action || null

    //console.log('RESERVATIONS: ', reservations)

    // Ensure data is an array
    let displayData = Array.isArray(data) ? [...data] : [];

    //Determine which data to show
    if (filter?.value === 'isUndefined') { 
        displayData = removeReservedItems(displayData, reservations);
    } else if (filter?.value === 'isNotUndefined') {
        displayData = Array.isArray(reservations) ? [...reservations] : [];
        //data = data.filter(item => item[filter.field] !== undefined && item[filter.field] !== 0);
    }
    

    return (
        <>
            {(displayData.length === 0) &&
                <ListRow><span>{t(emptyMessage)}</span></ListRow>
            }
            {displayData.length > 0 &&
                <div>
                    <ListHeader>
                        {col.map((col, key) => (
                            <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                {t(col.title)}
                            </ListCol>
                        ))}
                    </ListHeader>
                    {displayData.map((item, key) => (
                        <ListRow
                            key={key} to={path && pathId && item[pathId] ? `${path}${item[pathId]}` : ''}
                        >
                            {col.map((col, key) => (
                                <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                    {col.field && item ? item[col.field] : ''}
                                </ListCol>
                            ))}
                        </ListRow>
                    ))}
                    { //footer && <Footer>{m(footer.description, footerParams)}</Footer>}
                    description && <Footer>{m(description)}</Footer>}

                    {action && <Action action={action} entity={entity} useStore={useStore}/>}
                </div>}
        </>
    )
}

export default ReservationList;
