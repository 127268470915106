import { useRef, useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import Modal from "../modal/Modal";
import SelectMenuItem from "./SelectMenuItem";
import useTranslation from "../../hooks/useTranslation";
import { TextControl } from "../generic/Core";

// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

//Import KeyPress Utils:
import useKeyPress from "../../hooks/useKeyPress";

const SearchField = styled(TextControl)``;

const ListGroup = styled.ul`
  --list-group-bg: ${({ theme }) => theme.dropdown.background};
  --list-group-border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  background-color: var(--list-group-bg);
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--list-group-border-radius);
  overflow-x: hidden;
  min-height: 40rem;
`;

const SearchResults = styled.div`
  position: absolute;
  top: 60px;
  width: calc(100% - 20px);
  max-height: calc(100% - 60px);
  overflow-y: auto;
  z-index: 101;

  &.visible {
    visibility: visible;
  }

  &.invisible {
    visibility: hidden !important;
  }
`;

const Jumper = ({ headers }) => {
  const scroll = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <JumpWrapper>
      {headers.map((h, i) => (
        <JumpItem
          key={h.id}
          onClick={() => {
            scroll(`${h.id}`);
          }}
        >
          {h.name}
        </JumpItem>
      ))}
    </JumpWrapper>
  );
};

const JumpWrapper = styled.div`
  position: absolute;
  width: 10px;
  height: calc(100% - 60px);
  right: 30px;
  top: 60px;
  z-index: 2000;
`;

const JumpItem = styled.div`
  //Resizes the font-size based on the screen size. Each Font is 1.8% of Viewport Height
  font-size: calc(1.8vh);
`;

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const SelectModal_m = ({
  data,
  valueName,
  isVisible,
  setIsVisible,
  onSelect,
  entity,
  modalTitle,
  modalOpacity,
  options,
  menuItemField = 'name',
  persistValue,
  testId
}) => {

  const { t } = useTranslation();
  const [cursor, setCursor] = useState(-1);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const searchResultRef = useRef(null);
  const focusRef = useRef(null);

  //Setting up API 
  const { getMe } = useApi();

  // Debounced search setter
  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 300), // 300ms delay
    []
  );

  // Handle search input changes
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length >= 2) { // Only trigger search if 2 or more characters
      debouncedSetSearch(value);
    } else {
      setDebouncedSearch(""); // Clear results if less than 2 characters
    }
  };

  // Use debounced search value for query
  const searchQuery = useQuery(
    [`${entity}-search`, entity, debouncedSearch], // Include entity and search term in query key
    () => getMe(`${entity}?searchString=${debouncedSearch}`),
    {
      keepPreviousData: false, // Don't keep previous data to ensure fresh results
      enabled: debouncedSearch.length >= 2, // Only run query if 2 or more characters
      cacheTime: 0, // Don't cache results to ensure fresh data each time
      refetchOnWindowFocus: false // Prevent refetch on window focus
    }
  );

  const scrollIntoView = (position) => {
    if (searchResultRef.current) {
      searchResultRef.current.scrollTo({
        top: position,
        behavior: "smooth",
      });
    }
  };

  //On Load: Focus on the search Field
  useEffect(() => {
    focusRef.current?.focus();
  }, []);

  useEffect(() => {
    if (cursor < 0 || cursor > suggestions.length || !searchResultRef.current) {
      return () => { };
    }

    let listItems = Array.from(searchResultRef.current.children);
    listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
  }, [cursor]);

  //Load the value that is already in the DB
  useEffect(() => {
    if (persistValue && valueName) {
      setSearch(valueName);
      setDebouncedSearch(valueName);
    } else {
      setSearch("");
      setDebouncedSearch("");
    }
  }, [valueName, persistValue]);

  const suggestions = useMemo(() => {
    setCursor(-1);
    // Only scroll if ref is available
    if (searchResultRef.current) {
      scrollIntoView(0);
    }

    if (searchQuery.isLoading) {
      return [];
    }

    if (searchQuery.isError) {
      console.error('Search error:', searchQuery.error);
      return data.list; // Fallback to original data on error
    }

    // If there's a search term and we have results from the API, use those
    if (debouncedSearch && searchQuery.data) {
      return searchQuery.data.data || []; // Access the data array from the API response
    }

    // If no search term, use the original data
    return data.list;
  }, [data.list, debouncedSearch, searchQuery.data, searchQuery.isLoading, searchQuery.isError]);

  //Monitoring pressing Enter
  const enterPressed = useKeyPress("Enter");
  const escPressed = useKeyPress("Esc");

  //Keyboard events
  const keyboardNavigation = (e) => {
    if (e.key === "ArrowDown") {
      isVisible
        ? setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c))
        : showModal();
    }

    if (e.key === "ArrowUp") {
      setCursor((c) => (c > 0 ? c - 1 : 0));
    }

    if (e.key === "Escape") {
      setIsVisible(false);
    }

    if (e.key === "Enter" && cursor > 0) {
      setSearch(suggestions[cursor][menuItemField]);
      setIsVisible(false);
      onSelect(suggestions[cursor]);
    }
  };

  useEffect(() => {
    if (enterPressed) {
      if (cursor >= 0) {
        setSearch(suggestions[cursor][menuItemField]);
        setIsVisible(false);
        onSelect(suggestions[cursor]);
      }
    }
  }, [enterPressed]);

  useEffect(() => {
    if (escPressed) {
      setIsVisible(false);
    }
  }, [escPressed]);

  return (
    <Modal
      setShow={setIsVisible}
      show={isVisible}
      mode={"fullscreen"}
      className={"fullscreen"}
      minHeight={"35rem"}
      opacity={modalOpacity}
    >
      <Modal.Header setShow={setIsVisible}>
        <h3 style={{ margin: "0px" }}>{t(modalTitle)}</h3>
      </Modal.Header>
      <Modal.Body>
        <SearchField
          type="text"
          value={search || ""}
          name={`${entity}Name_${Math.random().toString(36).substr(2, 9)}`}
          onChange={handleSearchChange}
          onKeyDown={keyboardNavigation}
          ref={focusRef}
          autoComplete={`${entity}Name_${Math.random().toString(36).substr(2, 9)}`}
          data-testid={`${testId}.searchField`}
        />
        <SearchResults>
          <ListGroup ref={searchResultRef} id="select-menu">
            {searchQuery.isLoading ? (
              <SelectMenuItem name="Loading..." />
            ) : suggestions.map((item, idx) => (
              <SelectMenuItem
                displayId={options?.displayId || false}
                name={item[menuItemField]}
                idx={idx}
                key={idx}
                menuItemField={menuItemField}
                onSelectItem={() => {
                  setIsVisible(false);
                  setSearch(item[menuItemField]);
                  onSelect(item);
                }}
                isHighlighted={cursor === idx ? true : false}
                testId={testId}
                {...item}
              />
            ))}
          </ListGroup>
        </SearchResults>
        <Jumper headers={data.headers}></Jumper>
      </Modal.Body>
    </Modal>
  );
};

export default SelectModal_m;
