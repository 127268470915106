import { useEffect } from "react"
import styled, {keyframes} from "styled-components"
import Alert from "./Alert";
import { FlexBox } from "../generic/Layout";
import useTranslation from "../../hooks/useTranslation";

const fadeInAndSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutAndSlideUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
`;

const ToastDiv = styled.div`
    z-index:3000;
    position: fixed;
    top: 100px;
    left: 50vw;
    transform: translateX(-40vw);
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    opacity: 0.95;
    transition: opacity 1.5s ease-in-out;
    width: 80vw;

    @media (max-width: 576px) {
       width: 90vw;
       transform: translateX(-45vw);        
    }

    @media (min-width: 1280px) {
       width: 50vw;
       transform: translateX(-25vw);        
    }

`

const ToastContent = styled(Alert)`
  animation: ${fadeInAndSlideDown} 0.3s ease-in-out forwards;
`

function Toast({ title, message, onClose, type = 'info', duration = 3000 }) {
  const {t} = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration); // Use the provided duration

    return () => clearTimeout(timer);
  }, [onClose, duration]);

  return (
    <ToastDiv >
      <ToastContent className={type} >
        <FlexBox>
          <Alert.Icon type={type} />
          {title && <Alert.Heading>{t(title)}</Alert.Heading>}
        </FlexBox>
        {message && t(message)}
      </ToastContent>
    </ToastDiv>
  )
}

export default Toast;
