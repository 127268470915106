import styled from "styled-components"
import useTranslation from '../../hooks/useTranslation'
import useLocale from "../../hooks/useLocale"

import { useState } from "react"

import { LinkText } from "../generic/Core"
import IconButton from "../generic/IconButton"
import { Icon_Edit, Icon_Plus } from "../../styles/icons"
import { FlexBox } from "../generic/Layout"

import DisplayElementType from "./DisplayElementType"
import { getNestedValue } from "../../utils/objects/nestedValue"

import { useStore } from "../pageView/Page"

import Alert from '../toast/Alert'


const DisplayWrapper = styled.div`
    position: relative;
`

const IconButtonsContainer = styled.div`
    position: absolute;
    right: ${(props) => props.$right ? props.$right : '10%'};
    top: calc(50% - 1rem);
`

const DisplayCol = styled.div`
    flex: ${(props) => props.size};
    flex-basis: ${(props) => props.$grow};
    text-align: ${(props) => props.$align ? props.$align : 'start'};
`

const AlertWrapper = styled.div`
position: absolute;
top: 0.5rem;
right: 6rem;
width: 1.25rem;
padding: 0;
margin: 0;
color: #842029
    `

const validateWarning = (warning, displayData) => {
    if (!warning) return false
    const { field, compareField } = warning

    return displayData[field] !== displayData[compareField]
}


const Display = ({ section, clickAction, currency = '', type, idx, showAction = false, path = [] }) => {
    const [isHovered, setIsHovered] = useState(false)
    let displayData
    const sectionName = section?.meta?.name
    const warning = section?.meta?.warning || false

    const config = section?.display
    const linesArray = section?.meta?.linesArray

    const handleMouseEnter = () => { setIsHovered(true) }
    const handleMouseLeave = () => { setIsHovered(false) }

    // Use path for state access
    if (type === 'root') {
        [displayData] = useStore(s => getNestedValue(s, path))
    }
    if (type === 'lines') {
        let linesData
        [linesData] = useStore(s => getNestedValue(s, ['lines']))
        displayData = linesData[idx]

    }

    //Determines if to use the standard empty message or a dedicated one
    let emptyMessage = config?.emptyMessage || 'all.data.missing.message';
    let emptyField = displayData?.[config.emptyField] || false
    const isEmpty = !emptyField

    const { t } = useTranslation()

    const showWarning = validateWarning(warning, displayData)

    return (
        <DisplayWrapper
            name='formDisplayWrapper'
            className="hover-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {!isEmpty && <FlexBox>
                {
                    displayData && config.columns.map((column, key) => (
                        <DisplayCol key={key} size={column.size} $grow={column.grow} $align={column.align}>
                            {column.fields && column.fields.map((field, key) => {
                                return (
                                    <DisplayElementType key={key} displayData={displayData} element={field} $variant={field.variant}
                                        currency={currency}>
                                    </DisplayElementType>
                                )
                            })
                            }
                        </DisplayCol>
                    ))}
            </FlexBox>
            }
            {isHovered && !section.meta.readOnly && !isEmpty && !config.isHoveredDisabled && <IconButtonsContainer $right={config.editPos}>
                <IconButton icon={Icon_Edit} $rounded $variant='secondary' clickAction={clickAction} />
            </IconButtonsContainer>}
            {showAction && <IconButtonsContainer $right={config.editPos}>
                <IconButton icon={Icon_Edit} $rounded $variant='secondary' clickAction={clickAction} />
            </IconButtonsContainer>}
            {isEmpty && !config.isEmptyDisabled && <div>
                <FlexBox>
                    <IconButton icon={Icon_Plus} $rounded $variant='primary' $size='2rem' clickAction={clickAction} testId={`${sectionName}.add`} />
                    <LinkText onClick={clickAction}>{t(emptyMessage)}</LinkText>
                </FlexBox>
            </div>}
            {warning && showWarning && <AlertWrapper>
                <Alert.Icon type='warning' />
            </AlertWrapper>}
        </DisplayWrapper>
    )
}

export default Display
