import styled from "styled-components";

const StyledRadioGroup = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.$columns}, 1fr)`};
  gap: 1rem;
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-block: 0.5rem;
  border: 2px solid ${props => props.$checked ? props.theme.btn.primary : '#e2e8f0'};
  border-radius: 0.5rem;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-bottom: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`;


const RadioGroupItem = ({ value, label, icon, checked, onChange, groupId, testId }) => {
  const id = `radio_${groupId}_${value}`;
  return (
    <div>
      <HiddenRadio
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        name={`radio-group-${groupId}`}
        
      />
      <StyledLabel htmlFor={id} $checked={checked} data-testid={testId}>
        {icon && icon}
        {label}
      </StyledLabel>
    </div>
  );
};

const RadioGroup = ({ value, onChange, options, columns = 4, groupId, testId }) => {
  return (
    <StyledRadioGroup role="radiogroup" $columns={columns}>
      {options.map((option, idx) => (
        <RadioGroupItem
          key={`${groupId}_${option.value}`}
          value={option.value}
          label={option.label}
          icon={option.icon}
          checked={value === option.value}
          onChange={() => onChange(option.value)}
          groupId={groupId}
          testId = {`${testId}.${idx+1}`}
        />
      ))}
    </StyledRadioGroup>
  );
};

export { RadioGroup, RadioGroupItem };
