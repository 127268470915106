import styled from "styled-components";
import addHeaders from "../../utils/addHeader";
import ConnectControl from "../select/ConnectControl";

// Setup Use of Data
import { dataListData } from "../../model/components/dataListData";

//This allows to return the data read from the select entities
import generateSetValueObject from "../select/generateSetValueObject";

//Setting up the Wrapper
const DataListContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
`

//Select takes an entity like 'unit' a value like {name:'meter',id:'1'} a setter and options object.
const DataList = ({ entity, valueName, valueMap, setValue, options, isRed, modalTitle, modalOpacity, placeholder, testId }) => {
    if (!options) { options = {} }

    const data = dataListData[entity]

    const result = addHeaders(data, options.withHeaders)
    //Return Data to Form

    const returnData = (data, valueMap) => {
        const result = generateSetValueObject(data, valueMap);
        setValue(result);
    }

    if (true) {
        return (
            <>
                <div>
                    <DataListContainer id='DataListContainer'>
                        <ConnectControl
                            valueName={valueName}
                            setValue={setValue}
                            data={result}
                            placeholder={placeholder}
                            onSelect={selection => returnData(selection, valueMap)}
                            entity={entity}
                            modalTitle={modalTitle}
                            modalOpacity={modalOpacity}
                            options={options}
                            isRed={isRed}
                            $allwaysOn={true}
                            testId={testId}
                        />
                    </DataListContainer>
                </div>
            </>
        )
    }
};

export default DataList;