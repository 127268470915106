import { useState, useRef, useEffect } from "react";
import Calendar from "./Calendar";
import styled from "styled-components";
import dateFormats from '../../data/dateFormats.json'

import { Form } from "../form/FormComponents";
import useOutsideClick from "../../hooks/useOutsideClick";
import moment from "moment/moment";

import * as AiIcon from 'react-icons/ai'

//Getting local variables from the profile
import useProfile from '../../hooks/useProfile'
import { snapshot } from "valtio";

const InputField = styled(Form.Control)`
  
`
const DateIcon = styled.div`
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 20px;
`


const DateWrapper = styled.div`
  position: relative;

  p {
    font-size: 1rem;
  }
`


const DateInput = ({ name, value, setValue, align, testId }) => {
  //console.log('Rendering Date Input')
  const { pState } = useProfile()
  const profile = snapshot(pState)
  const locale = dateFormats[profile.currentOrgCountry]

  const [displayDate, setDisplayDate] = useState('');
  const [date, setDate] = useState(new Date())
  const [showCalendar, setShowCalendar] = useState(false);


  const handleClickOutside = event => {
    setShowCalendar(!showCalendar);
  };

  //Managing Outside Clicks
  const menuRef = useRef(null)
  useOutsideClick(menuRef, handleClickOutside, 'date');

  //On Load
  useEffect(
    () => {
      setDisplayDate(convertToString(date, locale))
  /*    setValue(
        { target: { name: name, value: date.toISOString() } }) */
    }

    , []
  )


  //on Loaded Value
  useEffect(
    () => {
      if (value) {
        const mDate = moment(value)
        if (mDate.isValid()) {
          setDisplayDate(convertToString(mDate.toDate(), locale))
          setDate(mDate.toDate())
        }
      }
    }, [value]
  )


  const handleInputChange = (e) => {
    const stringDate = e.target.value
    setDisplayDate(stringDate)
    const mDate = moment(stringDate, locale, true)
    if (mDate.isValid()) {
      setValue(
        { target: { name: name, value: convertToIso(mDate) } })
      setDate(mDate.toDate())
    }
  };

  const handleCalendarToggle = (e) => {
    setShowCalendar(!showCalendar);
    const mDate = moment(displayDate, locale, true)
    if (mDate.isValid()) {
      setDate(mDate.toDate())
    }

  };

  const handleDateSelect = (d) => {
    setValue(
      { target: { name: name, value: convertToIso(d) } })
    setDisplayDate(convertToString(d, locale))
    setShowCalendar(false);
  };


  // Function to handle down key press
  const handleKeyDown = (e) => {
    e.preventDefault();

    if (e.key === "ArrowDown") {
      handleCalendarToggle(e);
    }

    if (e.key === "Escape") {
      handleCalendarToggle(e);
    }
  };

  return (
    <DateWrapper id="dateWrapper">
      <InputField
        name='Toggle'
        id="date"
        type="text"
        value={displayDate}
        onChange={handleInputChange}
        onClick={handleCalendarToggle}
        autoComplete="off"
        onKeyDown={handleKeyDown}
        data-testid={testId}
      />
      <DateIcon><AiIcon.AiOutlineCalendar /></DateIcon>
      {<Calendar isVisible={showCalendar} ref={menuRef} date={date} setDate={setDate} onSelect={handleDateSelect} $align={align} />}
    </DateWrapper>
  );
};


//Converts the date to ISO at 12:00 Local Time
function convertToIso(localDate) {
  if (!moment.isMoment(localDate)) {
    localDate = moment(localDate)
  }
  const newDate = localDate.hour(12)
  return newDate.toISOString()
}

function convertToString(localDate, locale) {
  if (!moment.isMoment(localDate)) {
    localDate = moment(localDate)
  }
  const newDate = localDate.format(locale)
  return newDate
}

export default DateInput;
