import { localizeObj } from "../objects/localizeObject";

//This function finds the keys referenced as placeholders and returns their value 
//Localized for amounts and dates
function replacePlaceholder(string, object, lang) {
    let localizedObj = { ...object }
    localizedObj = localizeObj(localizedObj, lang)
    const regex = /\${(.*?)}/g; // Regular expression to match placeholders like ${...}
    return string.replace(regex, (match, placeholder) => {
        const localizedValue = localizedObj[placeholder.trim()];
        return localizedValue || match;
    });
}


const td = (label) => {
    return label
}


const processDefaultType = (item, result, profile, values = {}, t) => {
    if (item.defaultType) {
        // Check the value of defaultType
        if (item.defaultType === 'date') {
            // Convert the current date to ISO format
            const currentDate = new Date().toISOString();
            // Add key-value pair to result object
            if (!result[item.id]) result[item.id] = currentDate;
        } else if (item.defaultType === 'nserie') {
            let nextNumber
            if (profile?.profile?.nSeries?.[item.entity]?.nextValue) {
                nextNumber = profile.profile.nSeries[item.entity].nextValue
            } else { nextNumber = '1001' }
            result[item.id] = nextNumber;
        } else if (item.defaultType === 'value') {
            // Use the value from "defaultValue" field
            result[item.id] = item.defaultValue;
        } else if (item.defaultType === 'merge') {
            // Use the value from "defaultValue" field
            result[item.id] = replacePlaceholder(t(item.defaultValue), values, profile.currentOrgLanguage);
        } else if (item.defaultType === 'country') {
            // Use the value from "defaultValue" field
            result[item.id] = profile.currentOrgCountry;
            result[item.display] = profile.currentOrgCountryName;
        } else if (item.defaultType === 'number') {
            // Use the value from "defaultValue" field
            result[item.id] = item.defaultValue;
        } 
    }
    return result
}

export function processLineDefaults(section, profile) {
    let result = {};

    // Process each object in the section's array
    if (section.groups) {
        // Process objects of type "group" with "fields" property
        section.groups.forEach((groupObj) => {
            if (groupObj.type === 'group') {
                groupObj.fields.forEach((field) => {
                    const value = processDefaultType(field, {}, profile)
                    result = { ...result, ...value }
                }
                )
            } else {
                // Process objects that are not of type "group"
                if (groupObj.defaultType) {
                    const value = processDefaultType(groupObj, {}, profile)
                    result = { ...result, ...value }
                }
            }
        });
    }

    if (section?.init) {
        section.init.forEach((field) => {
            const value = processDefaultType(field, {}, profile)
            result = { ...result, ...value }
        })
    }


    return result;
}

export const convertObjectToMessage = (inputObject) => {
    const messageArray = Object.keys(inputObject).map((key) => ({
        target: {
            name: key,
            value: inputObject[key],
        },
    }));
    return messageArray;
}





function processDefaults(sections, profile, defaultData = [], values = {}, trans = td) {
    let result = {};


    // Process each object in the section's array
    sections.forEach((section) => {
        if (section.groups) {
            // Process objects of type "group" with "fields" property
            section.groups.forEach((groupObj) => {
                if (groupObj.type === 'group') {
                    groupObj.fields.forEach((field) => {
                        const sections = [{ groups:[field] }]  
                        //const value = processDefaultType(field, {}, profile, values, trans)
                        const value = processDefaults(sections, profile, defaultData, values, trans)
                        result = { ...result, ...value }
                    }
                    )
                } else {
                    // Process objects that are not of type "group"
                    if (groupObj.defaultType) {
                        const value = processDefaultType(groupObj, {}, profile, values, trans)
                        result = { ...result, ...value }
                    }
                }
            });
        }
    });

    defaultData.forEach((elem) => {
        const value = processDefaultType(elem, {}, profile)
        result = { ...result, ...value }
    })

    result.defaulted = true
    return result;
}

export default processDefaults