import { useState } from "react"
import useNotification from "../../hooks/useNotification"

import useApi from "../../hooks/useApi"
import SpinnerButton from "../generic/SpinnerButton"
import generateEndpoint from "../../utils/generateEndpoint"
import { useParams } from "react-router-dom"
import useTranslation from "../../hooks/useTranslation"
import runLogic from "../../../../common/logic/runLogic"


const Action = ({ action, entity, useStore }) => {

    const [data, setData] = useState(null)

    const [state, setState] = useStore(s => s)
    const { showNotification, NotificationComponent } = useNotification();
    
    const { postMe } = useApi()
    const label = action['buttonLabel']

    const path = action.endpoint
    const params = useParams()

    const { t } = useTranslation()

    const { id } = params
    const endpoint = generateEndpoint(path, id)


    const sendRequest = async (e) => {
        e.preventDefault()
        const response = await postMe(endpoint, state)
        console.log('<Data> Here is the response : ', response)
        //setData(response)
        if (response.status === 'success') {
            showNotification(response.title, response.message, 'success')
            const processedData = runLogic(response.data, entity)
            setState(processedData)
        } else {
            showNotification(response.title, response.error, 'error')
        }
    }

    return (
        <div>
            {NotificationComponent}
            <SpinnerButton onClickEvent={(e) => sendRequest(e)} testId={`${action.name}.button`}>{t(label)}</SpinnerButton>
        </div>
    )
}

export default Action