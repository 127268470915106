// Hook to deal with Profile
import { useEffect } from 'react'
import useApi from './useApi'
import { sState } from "../context/settingContext"
import { useSnapshot } from 'valtio'

//Import Read and Save to Local Store
import { getFromLS, saveToLS } from "../utils/localStore/localStore"

const useSettings = () => {
  const { getMe } = useApi()
  const snap = useSnapshot(sState)
  const sData = snap.data

  useEffect(
  
    () => {
    //  console.log('sData has changed', sData)
    }
  , [sData]
)

  const initializeSettings = async (setting) => {
    if (setting) {
      let response = await getMe(`${setting}`)
      saveToLS(setting, "data", response)

      if (!response.length > 0) {
        response = getFromLS(setting, "data")
      }

      sState.data = response.data
      sState.type = setting

    }
  }

  return { initializeSettings, sState, sData };
}

export default useSettings;