import { useRef, useState, useLayoutEffect, useEffect } from 'react'
import InputElem from './components/InputElem'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from "styled-components"

//Import Button
import Loader from '../../components/generic/Loader'
import SpinnerButton from '../../components/generic/SpinnerButton'

//Importing Auth
import useAuth from "../../hooks/useAuth"
import axios from "../../api/axios"
const LOGIN_URL = '/token'
const SIGNUP_URL = '/signup'

//Importing the translation stack
import useTranslation from '../../hooks/useTranslation'

//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\.@$!%*?&])[A-Za-z\d@$\.!%*?&]{8,24}$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$\.!%*?&]{8,24}$/
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const EntryTitle = styled.h2` 
`

const EntryError = styled.p` 

    &.errMsg {
        color: indianred;
    }

    &.offscreen {
        display: none;
    }
`


const SmallP = styled.p`
    font-size: var(--font-XS);

    &>span{
        margin-left: 0.5rem;
    }
`

const SmallLink = styled(Link)`
    font-size: var(--font-XS);    
`

const Background = styled.div`
    background-image: url("/background.jpg");
    background-size: 'cover';
    background-position: 'center';
    height: 100vh;
`

const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh
`

const EntrySection = styled.section`
    background: ${({ theme }) => theme.font.background};
    color: ${({ theme }) => theme.font.color};
    display: grid;
    align-items: center;
    justify-content: center;
    height: 60vh;
    min-height: 350px;
    padding: 1.5rem;
    border-radius: 1rem;
    max-width:350px;
`

//The Signup/Login Form:
const EntryForm = (props) => {
    //   console.log('Rendered: Entry Form')
    const { Auth, setAuth } = useAuth();

    //Record Initial Nav Path for use when redirecting. Overrides "/" with "/home"
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname || "/home"
    if (from === '/') { from = '/home' }

    //Enabling Translations
    const { t, loadTrans } = useTranslation()

    //Setting up reference fields for validation
    const errorRef = useRef()
    const emailRef = useRef()

    //Adding State Fields
    const [isLoading, setIsLoading] = useState(true)

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState('false')
    const [emailFocus, setEmailFocus] = useState('false')

    const [pwd, setPwd] = useState('')
    const [validPwd, setValidPwd] = useState('false')
    const [pwdFocus, setPwdFocus] = useState('false')

    const [matchPwd, setMatchPwd] = useState('')
    const [validMatch, setValidMatch] = useState((props.purpose === 'login') ? true : false)
    const [matchFocus, setMatchFocus] = useState('false')


    //Define the error message
    const emailNoteText = <>{t('Entry.form.email.note.text')}</>
    const pwdNoteText = (
        <span><>{t('Entry.form.password.note.text')}</><br />
            <>{t('Entry.form.password.note.subtext')}</> <span aria-label="exclamation mark">!</span><span aria-label="at symbol">@</span><span aria-label="hash tag">#</span><span aria-label="dollar sign">$</span>.</span>
    )
    const confirmPwdText = 'Must match the first password input field.'

    //Adding State for Error Messages
    const [errMsg, setErrMsg] = useState('')

    const loadTranslations = async () => {
        await loadTrans()
        setIsLoading(false)
    }


    //On Load of Entry Form, reload the translation to ensure we have the translation for entry. 
    useEffect(() => {
        //emailRef.current.focus()
        loadTranslations()
    }, [])

    //On Change email: Validate the email
    useLayoutEffect(
        () => {
            const result = EMAIL_REGEX.test(email)
            setValidEmail(result)
        }, [email])

    //On Change Password: Validate the password and the match password field
    useLayoutEffect(() => {
        const result = PWD_REGEX.test(pwd);
        setValidPwd(result)
        if (pwd !== '' && props.purpose === 'register') {
            const match = pwd === matchPwd
            setValidMatch(match)
        }
    }, [pwd, matchPwd])

    //On change of any field: Blank out the error message
    useEffect(() => {
        setErrMsg('')
    }, [pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const v1 = EMAIL_REGEX.test(email)
        const v2 = PWD_REGEX.test(pwd)
        if (!v1 || !v2) {
            setErrMsg(t('Entry.form.error.invalid.entry'))
            return
        }
        let url;
        (props.purpose === 'login') ? url = LOGIN_URL : url = SIGNUP_URL
        try {

            const response = await axios.post(
                url,
                {
                    email: email,
                    password: pwd
                },
                { headers: { 'Content-Type': 'application/json' } }
            )

            const { accessToken, refreshToken, id, orgId } = response.data

            await setAuth({ email, accessToken, refreshToken, id, orgId })

            //setSuccess(true)
            if (!orgId) { navigate('/org', { replace: true, origin: from }) } else {
                //Navigate to original URL or Home page
                navigate(from, { replace: true })
            }

        } catch (err) {
            console.log(err)
            if (!err.response) {
                setErrMsg(t('Entry.form.error.no.response'))
            } else if (err.response.status === 400) {
                setErrMsg(t('Entry.form.error.mismatch'))
            } else if (err.response.status === 403) {
                setErrMsg(t('Entry.form.error.unauthorized'))
            } else {
                setErrMsg(t('Entry.form.error.login.failed'))
            }
            errorRef.current.focus();
        }
    }

    if (isLoading) {
        return (<Loader />)
    } else
        return (
            <Background>
                <Center>
                    <EntrySection>
                        <EntryTitle data-testid='entry.title'>
                            {(props.purpose === 'login') ? <>{t('Entry.form.login.title')}</> : <> {t('Entry.form.register.title')}</>}
                        </EntryTitle>
                        <EntryError ref={errorRef} className={errMsg ? 'errMsg' : 'offscreen'}>{errMsg}</EntryError>
                        <form onSubmit={handleSubmit}>
                            {/*EMAIL SECTION*/}
                            <InputElem
                                label={t('Entry.form.email')}
                                validInput={validEmail}
                                type='email'
                                id='email'
                                noteId='emailnote'
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                noteText={emailNoteText}
                                focus={emailFocus}
                                value={email}
                                ref={emailRef}
                            />
                            {/*PASSWORD SECTION*/}
                            <InputElem
                                label={t('Entry.form.password')}
                                validInput={validPwd}
                                type='password'
                                id='password'
                                noteId='pwdnote'
                                onChange={(e) => setPwd(e.target.value)}
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                noteText={pwdNoteText}
                                focus={pwdFocus}
                                value={pwd}
                            />
                            {/*MATCHING PASSWORD SECTION*/}
                            {(props.purpose === 'register') ? (<InputElem
                                label={t('Entry.form.confirm.password')}
                                validInput={validMatch}
                                type='password'
                                id='match'
                                noteId='matchnote'
                                onChange={(e) => setMatchPwd(e.target.value)}
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                                noteText={confirmPwdText}
                                focus={matchFocus}
                                value={matchPwd}
                            />) : <></>}
                            {/* BUTTON */}
                            <SpinnerButton
                                width='300px'
                                $variant='primary'
                                disabled={!validEmail || !validPwd || !validMatch ? true : false}
                                name='submit'
                                time='4000'
                                testId='entry.submit.button'
                            > {(props.purpose === 'login') ? (t('Entry.form.login.button')) : (t('Entry.form.register.button'))}
                            </SpinnerButton>
                        </form>
                        {/* Button Message */}
                        {(props.purpose === 'login') ? (
                            <SmallP>{t('Entry.form.login.footer', 'g')}<span><SmallLink to="/register" >{t('Entry.form.login.footer.link')}</SmallLink></span></SmallP>
                        ) : (
                            <SmallP>{t('Entry.form.register.footer')}<span><SmallLink to="/login" >{t('Entry.form.register.footer.link')}</SmallLink></span></SmallP>
                        )}

                    </EntrySection>
                </Center>
            </Background>
        )
}


export default EntryForm