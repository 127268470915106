import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import CloseButton from '../generic/CloseButton'
import useOutsideClick from '../../hooks/useOutsideClick'

//Import the Animation
import { SlideUp } from '../../styles/animations'

export const ModalBackdrop = styled.div`
    --backdrop-zindex: 1050;
    --backdrop-bg: ${({ theme }) => theme.modal.backdrop};
    --backdrop-opacity: ${({ opacity }) => opacity ? opacity : 0};
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--backdrop-bg);
    opacity:0%;
   
    visibility: ${({ $show }) => $show === true ? 'visible' : 'hidden'};


    //Animation:
    &.animate:not(.fullscreen) {
        transition: opacity 0.15s linear;
        opacity: var(--backdrop-opacity);        
    }

    &.animate.fullscreen {
        @media (min-width: 576px) {
           transition: opacity 0.15s linear;
           opacity: var(--backdrop-opacity);        
        }
    }


`

const ModalArea = styled.div`
    --modal-zindex: 1055;
    --modal-width: 500px;
    --modal-padding: 1rem;
    --modal-margin: 0.5rem;
    --modal-color: ${({ theme }) => theme.font.color};
    --modal-bg: ${({ theme }) => theme.modal.background} ;
    --modal-border-color: ${({ theme }) => theme.modal.border};
    --modal-border-width: 1px;
    --modal-border-radius: 0.5rem;
    --modal-box-shadow: 0 0.125rem 0.25rem ${({ theme }) => theme.modal.shadow};
    --modal-inner-border-radius: calc(0.5rem - 1px);
    --modal-header-padding-x: 1rem;
    --modal-header-padding-y: 1rem;
    --modal-header-padding: 1rem 1rem;
    --modal-header-border-color: ${({ theme }) => theme.modal.divider};
    --modal-header-border-width: 1px;
    --modal-title-line-height: 1.5;
    --modal-footer-gap: 0.5rem;
    --modal-footer-bg: ;
    --modal-footer-border-color: ${({ theme }) => theme.modal.divider};
    --modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--modal-zindex);
    visibility: ${({ $show }) => $show === true ? 'visible' : 'hidden'};
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    
    @media (min-width: 576px)
     {
    --modal-margin: 1.75rem;
    --modal-box-shadow: 0 0.5rem 1rem ${({ theme }) => theme.modal.shadow_m};
    }
`

const ModalDialog = styled.div`

    //Centered
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--modal-margin) * 2);

    position: relative;
    width: auto;
    margin: var(--modal-margin);
    pointer-events: none;


    &.animate{
        transition: transform 0.15s ease-out;
        transform: translateY(-50px);
    }

    @media (min-width: 576px)
     {
    max-width: var(--modal-width);
    margin-right: auto;
    margin-left: auto;
    }

    &.fullscreen{
        @media (max-width: 575.98px) {
        width: 100vw;
        max-width: none;
        min-height: 100vh; /* Minimum height is the full viewport height */
        margin: 0;
        transform: translateY(0px);
        animation: ${SlideUp} 0.25s ease-in-out forwards;
            }
    }

`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme, $color }) => $color? $color : theme.font.color}; 
    pointer-events: auto;
    background-color: ${({ theme, $bg }) => $bg? $bg : theme.modal.background}; 
    background-clip: padding-box;
    border: var(--modal-border-width) solid var(--modal-border-color);
    border-radius: var(--modal-border-radius);
    outline: 0;
    min-height: ${(props) => props.$minHeight ? props.$minHeight : '15rem'};

    &.fullscreen{

    @media (max-width: 575.98px) {
    min-height: 100vh;
    border: 0;
    border-radius: 0;
}
}

`

let Modal = ({ setShow, show, children, mode = '', handleKeyDown, minHeight, opacity = 0.5, $padding = '1rem', $color, $bg }) => {
    const handleClick = () => setShow(false)

    //Handle Click Outside the Object
    const myRef = useRef(null);
    useOutsideClick(myRef, handleClick, 'modal');


    return (
        <>
            <ModalBackdrop name='Modal-Backdrop' $show={show} opacity={opacity} className={show ? 'animate' : ''} />
            <ModalArea name='ModalArea' $show={show} >
                <ModalDialog onKeyDown={handleKeyDown} name='Modal-Dialog' className={show ? `animate ${mode}` : ''}>
                    <ModalContent $color={$color} $bg={$bg} ref={myRef} $minHeight={minHeight} className={show ? `animate ${mode}` : ''} >
                        {children}
                    </ModalContent>
                </ModalDialog>
            </ModalArea>
        </>)
}


const ModalTitle = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--modal-header-padding);
    border-bottom: var(--modal-header-border-width) solid;
    border-bottom-color: ${({ theme, $color }) => $color? $color : theme.modal.divider};
    border-top-left-radius: var(--modal-inner-border-radius);
    border-top-right-radius: var(--modal-inner-border-radius);
    
    h4 {
        margin-right: 2.25rem;
    }
`

const ModalHeader = ({ children, setShow, $color }) => {
    return (
        <>
            <ModalTitle $color={$color}>{children}</ModalTitle>
            <CloseButton $color={$color} handleClick={() => setShow(false)} $size='1.5rem'></CloseButton>
        </>

    )
}


const ModalBody = styled.div`
    position: relative;
    flex: 1 1 auto; 
    padding: ${({ $padding }) => $padding ? $padding : '1rem'};
`


const ModalFooter = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--modal-padding) - var(--modal-footer-gap) * .5);
    background-color: var(--modal-footer-bg);
    border-top: var(--modal-footer-border-width) solid;
    border-top-color: ${({ theme, $color }) => $color? $color : theme.modal.divider};
    border-bottom-right-radius: var(--modal-inner-border-radius);
    border-bottom-left-radius: var(--modal-inner-border-radius);
`



Modal.Dialog = ModalDialog
Modal.Content = ModalContent
Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal