import { useState, useEffect } from "react"
import styled from "styled-components"
import { Button } from "./Core"
import { Spinner } from "./Loader"
import pause from "../../utils/pause"

const StyledSpinner = styled(Spinner)`
    width: 1rem;
    height: 1rem;
`

const SpinWrapper = styled.span`
  padding-left:1rem;  
`


const StyledButton = styled(Button)`
  position: relative;
  overflow: hidden;
  width: ${({ width }) => width ? width : ''};

  & span {
  }
`

const SpinnerButton = ({ type, width, children, $variant, disabled, name, $shadow, onClickEvent, time = 1000, testId = 'submit.button' }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible === true) {
      disableSpinner()
    }

  }, [visible])

  const disableSpinner = async () => {
    await pause(time)
    setVisible(false)
  }

  const handleOnClick = async (e) => {
    setVisible(true)
    if (onClickEvent !== undefined) onClickEvent(e)
  }

  return (
    <StyledButton type={type} name={name} $shadow={$shadow} width={width} $variant={$variant} disabled={disabled} onClick={handleOnClick} data-testid={testId}>
      <span>{children}</span>
      {visible && <SpinWrapper><StyledSpinner size={'1.5rem'}></StyledSpinner></SpinWrapper>}
    </StyledButton>)
}

export default SpinnerButton