import { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import styled from "styled-components";

//Import Icon Buttons & Icons
import IconButton from "../generic/IconButton";
import { Icon_Arrow_Left_Simple, Icon_Arrow_Right_Simple, Icon_Download } from "../../styles/icons";

const PDFBlock = styled.div`
    width: 100%;
`
const ButtonsLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.25rem;
`

const PdfView = ({ pdf, width }) => {
    //Registering the Worker for react-pdf
    useEffect(() => {
        //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.min.mjs',
            import.meta.url,
          ).toString();
    }, [])

    const ref = useRef(null)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    const handleDownload = () => {
        if (pdf) {
            // Create a Blob from the arrayBuffer
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const fileURL = `${window.URL.createObjectURL(blob)}`;
            window.open(fileURL, '_blank');
        }
    };



    return (
        <PDFBlock ref={ref}>
            <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={width}
                />
            </Document>
            <ButtonsLine>
                <p>
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </p>
                <div>
                    <IconButton icon={Icon_Download} clickAction={handleDownload} />

                    <IconButton
                        $variant='primary' type="button" disabled={pageNumber <= 1} clickAction={previousPage}
                        icon={Icon_Arrow_Left_Simple}
                    >
                    </IconButton>
                    <IconButton
                        icon={Icon_Arrow_Right_Simple}
                        $variant='primary'
                        type="button"
                        disabled={pageNumber >= numPages}
                        clickAction={nextPage}
                    >
                    </IconButton>
                </div>
            </ButtonsLine>
        </PDFBlock>
    );
}

export default PdfView