import * as MdIcons from "react-icons/md";
import styled from "styled-components";

const CollapseContainer = styled.div`
    position: absolute;
    top: ${ (props) => props.$top? props.$top: '0.25rem' } ;
    right: ${ (props) => props.$right? props.$right: '0.75rem' } ;
    height: 1.5rem;
    z-index: 100;
`

const CollapseCloseIcon = <MdIcons.MdExpandLess />
const CollapseOpenIcon = <MdIcons.MdExpandMore />


const Collapse = ({expanded, toggleExpanded, top, right, testId = 'collapse.button'}) => {


    return (
        <CollapseContainer data-testid={testId} onClick={toggleExpanded} $top={top} $right={right} name='Collapse'>
            {expanded ? CollapseCloseIcon : CollapseOpenIcon}
        </CollapseContainer>
    )

}

export default Collapse