//Importing UI Components
import { Form } from '../form/FormComponents'
import Collapse from '../generic/Collapse'
import { memo } from 'react'
import runLogic from '../../../../common/logic/runLogic'

//Setting up subcomponents
import Display from '../display/Display'
import CoreForm from './CoreForm'

const CardViewFormSection = memo(({ section, expanded, toggleExpanded, validated, dispatch, entity, useStore, options }) => {
    let readOnly = false
    let showCollapse = true
    if (section.meta.collapsible === false) showCollapse = false
    if (section.meta.readOnly === true) readOnly = true

    //Ignore items that do not match the condition
    if (section.meta?.condition && section.meta?.condition?.mode !== options.mode) return <></>


    const [state, setState] = useStore(s => s)

    const toggleSection = () => {
        toggleExpanded(section.meta.name)
    }

    if (!section) {
        // Handle the case when section is not provided or is empty
        return <div>No section data available.</div>;
    }

    const timeStamp = () => {
        setState({ savedOn: new Date() })
    }

    const localDispatch = (action, data) => {
        if (action === 'close') {
            const processedData = runLogic(state, state.entity)
            setState(processedData)
            timeStamp()
            toggleSection()
        } else {
            dispatch(action, data)
        }
    }

    //console.log('re-rendering CardViewFormSection')
    return (

        <Form.Section name='FormSection' width={section.meta.width}>

            {!readOnly && showCollapse && <Collapse name="Collapse-Button" expanded={expanded} toggleExpanded={toggleSection} testId={`${section.meta.name}.toggle`} />}
            <CoreForm
                section={section}
                expanded={expanded}
                toggleSection={toggleSection}
                dispatch={localDispatch}
                validated={validated}
                entity={entity}
                useStore={useStore}
                options={options}
            />
            {
                !expanded && <Display
                    type='root' section={section} clickAction={toggleSection} >
                </Display>
            }
        </Form.Section>

    )

})


export default CardViewFormSection