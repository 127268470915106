import styled from 'styled-components'
import { useRef } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick'

import useSidebar from '../../hooks/useSidebar'
import { useSnapshot } from 'valtio'

//Import Sub-Components
import { sidebarData } from '../../model/components/sidebarData'
import SBSubMenu from './SBSubMenu'

//Importing Icons to use
import * as AiIcons from 'react-icons/ai'
import appLogo from '../../assets/logo'

//Import utilities for the URL
import { useLocation } from 'react-router-dom'
import extractSlug from '../../utils/extractSlug'

import { NavIconColor } from '../../styles/IconStyle'

const SideBarNav = styled.nav`
    background: ${({ theme }) => theme.nav.background};
    width: 15rem;
    display: 'flex';
    justify-content: center;
    transition: 350ms;
    z-index: 1001;
    @media (max-width: ${({ theme }) => theme.breakpoint.s}) {
    border-right: 2px solid ${({ theme }) => theme.area.border}
  }
    `

const MenuItemsWrap = styled.div`
    width: 100%;
    /*padding-left: calc(var(--bs-gutter-x) * .5);*/

    @media (max-width: 768px) {
    padding-left: 0;
  }
`

const SideBarWrapper = styled.div`
    z-index: 1000;
    flex-direction: column;
    width: 15rem;
    background-color: ${({ theme }) => theme.nav.background};
    min-height: 100vh !important;
    margin-left: 0rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    transition: 350ms;
    padding-left: 0;
    display: ${({ $visible }) => ($visible ? 'flex' : 'none')};

    @media (max-width: ${({ theme }) => theme.breakpoint.s}) {
    position: fixed;
    top: 0;
    left: 0;  
    border-right: 2px solid ${({ theme }) => theme.area.border};
  }
    `

const SidebarContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden; /* Hide overflowing content */
`

const SidebaContent = styled.div`
    /* Apply desired styles */
    height: 100%;
    overflow: auto; /* Enable scrolling */
`

const SideBarTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1.35rem;
    color: ${({ theme }) => theme.nav.font};
    padding-inline: 1rem;
    margin-block: 1rem;
`
const SideBarCloseBar = styled.div`
    font-size: 1.5rem;
    justify-content: flex-end;
    display: none;

    @media (max-width: 768px) {
    display: flex;
  }
`

const SideBarCloseButton = styled.div`
    padding: 0.5rem;
    position: absolute;
    right:0;
`

const homeNav = {
    title: 'Home',
    path: '/home',
    icon: <AiIcons.AiFillHome />
}

const Sidebar = () => {
    const path = useLocation()
    const location = extractSlug(path.pathname).toUpperCase()
    const key = location.toLowerCase()
    const data = sidebarData[key]

    const { toggleSidebar, sbState } = useSidebar()
    const snap = useSnapshot(sbState)
    const sidebarVisibility = snap.visible

    const handleOutsideClick = (e) => {
        toggleSidebar()
    }

    const closeSideBarOnMobile = (e) => {
        let element = document.getElementById('sideBarWrapper')
        let position = window.getComputedStyle(element, null).position
        // Closes the sidebar on Mobile
        if (position === 'fixed') {
            toggleSidebar()
        }
    }

    //Handle Click Outside the Object
    const myRef = useRef(null);
    useOutsideClick(myRef, handleOutsideClick, 'sidebar');

    return (
        <>
            <SideBarWrapper ref={myRef} id="sideBarWrapper" $visible={sidebarVisibility}>
                <SidebarContentWrapper>
                    <SidebaContent>
                        <NavIconColor>
                            <SideBarCloseBar>
                                <SideBarCloseButton onClick={toggleSidebar}><AiIcons.AiOutlineClose /></SideBarCloseButton>
                            </SideBarCloseBar>
                            <SideBarTitle id="SideBarTitle">
                                {appLogo}
                                {/* <AiIcons.AiFillAppstore /> */}
                                <span>{location}</span>
                            </SideBarTitle>
                        </NavIconColor>
                        <NavIconColor >
                            <SideBarNav>
                                <MenuItemsWrap >
                                    {
                                        data ?
                                            data.map(
                                                (item, index) => {
                                                    return <SBSubMenu item={item} key={index} handleClick={closeSideBarOnMobile} />
                                                }
                                            )
                                            : <SBSubMenu item={homeNav} key="1" />
                                    }
                                </MenuItemsWrap>
                            </SideBarNav>
                        </NavIconColor>
                    </SidebaContent>
                </SidebarContentWrapper>
            </SideBarWrapper>
        </>
    )
}

export default Sidebar