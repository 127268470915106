import { useState } from 'react'
import Alert from '../components/modal/Alert'

function useAlert() {
  const [alert, setAlert] = useState(null)
  const [title, setTitle] = useState(null)
  const [type, setType] = useState(null)

  const showAlert = (title = ' ', message = ' ', type = 'warning') => {
    setTitle(title)
    if (message) setAlert(message)
    setType(type)
  }

  const closeAlert = () => {
    setAlert(null)
    setTitle(null)
    setType(null)
  }

  const AlertComponent = alert && (
    <Alert message={alert} title={title} type={type} onClose={closeAlert} />
  )

  return {
    showAlert,
    AlertComponent,
  }
}

export default useAlert
