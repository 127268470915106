import { useEffect, forwardRef } from "react";
import { TextControl } from "../generic/Core";
import { FlexBox } from "../generic/Layout";
import useKeyPress from "../../hooks/useKeyPress";
import IconButton from "../generic/IconButton";
import { Icon_Search } from "../../styles/icons";
import { Spacer } from "../generic/Core";

const ConnectInput = forwardRef(({ showModal, placeholder, valueName, name, isRed, autoComplete, testId }, ref) => {
    const enterPressed = useKeyPress('Enter')

    useEffect(() => {
        const focusedElem = document.activeElement;
        if (enterPressed && focusedElem.name === name) {
            showModal()
        }
    }, [enterPressed]);

    return (
        <FlexBox >
            <TextControl 
                ref={ref}
                value={valueName} 
                name={name} 
                placeholder={placeholder}  
                onChange={(e) => console.log('New Value', e.target.value)} 
                autoComplete={autoComplete}
                {...(isRed && { required: true })}
                onClick={showModal}
                data-testid={testId}
            >
            </TextControl>
            <Spacer width={'0.5rem'} />
            <IconButton icon={Icon_Search} $rounded $variant='primary' $size='2.25rem' clickAction={showModal} />
        </FlexBox>
    );
});

export default ConnectInput;
