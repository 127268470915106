import BackButton from '../generic/BackButton'
import { Wrapper } from '../generic/Layout'
import useTranslation from '../../hooks/useTranslation'
import { Switcher } from '../generic/Switcher'
import styled from 'styled-components'

import { Button } from '../generic/Core'
import SpinnerButton from '../generic/SpinnerButton'
import IconButton from '../generic/IconButton'
import DisplayElement from '../display/DisplayElement'

const ButtonGroup = styled.div`
    display: flex;
`

import { useStore } from '../pageView/Page'

const CardViewTitle = ({ options, config, handleActions }) => {

    const [state] = useStore((s) => s)
    const { t } = useTranslation()

    //The Option array passed to switcher expects: type, id and name.
    let menuOptions

    if (config.cardTitle?.menuItems) {
        menuOptions = config.cardTitle.menuItems.filter(item => {
            // Check if dependency exists and state matches
            return !item.dependency || (item.dependency && state[item.dependency.id] === item.dependency.value);
        })
            .map(item => ({ type: 'item', id: item.action, name: t(item.label), ...item }));
    }

    const handleSubmit = (button) => {
        button.action ? button.action : button.action = 'save'
        button.confirm ? button.confirm : button.confirm = false
        button.modalConfig ? button.modalConfig : button.modalConfig = ''

        const option = { action: button.action, confirm: button.confirm, modalConfig: button.modalConfig }
        handleActions(option)
    }

    return (
        <Wrapper>
            <h2>
                <BackButton config={config} />
                <span>{options.mode === 'new' ? t(config.newTitle) : <DisplayElement string={state[config.titleField]} type={config?.titleType} />}</span>
                {config.title ? <span> {t(config.title)} </span> : <></>}
            </h2>
            <ButtonGroup>
                {//Getting Buttons from the config
                    config.cardTitle.buttons && config.cardTitle.buttons.map(
                        (button, k) => {
                            //Note Submit Button sends the form and it is a special case.
                            return (
                                (button.type === 'submit')
                                    ? <SpinnerButton $shadow name='submit' $variant={button.variant}
                                        key={`btn-${k}`} type={button.type}
                                        onClickEvent={e => handleSubmit(button)}
                                        testId={`${config.entity}.title.${button.action}`}
                                    >
                                        {t(button.label)}
                                    </SpinnerButton>
                                    : (button.size === 'icon') ? <IconButton
                                        icon={button.icon} $variant={button.variant} $size='2.5rem' clickAction={(e) => {
                                            handleActions(button)
                                        }}
                                    />
                                        : <Button $shadow={true} $variant={button.variant} key={`btn-${k}`} type={button.type}
                                            name={button.action}
                                            onClick={(e) => {
                                                console.log('Button clicked:', button);
                                                handleActions(button)
                                            }}>
                                            {t(button.label)}
                                        </Button>
                            )
                        }
                    )
                }
                {options.mode === 'new' ? <></> :
                    menuOptions && <Switcher currentOption={t(config.cardTitle.title)} options={menuOptions} handleSelect={handleActions} type={config.entity} ></Switcher>
                }
            </ButtonGroup>
        </Wrapper>

    )
}

export default CardViewTitle