import { useState, useRef } from "react"
import styled from "styled-components"

//Import UI components to be used
import { Form } from '../form/FormComponents'
import SelectModal_m from "./SelectModal_m"
import ConnectInput from "./ConnectInput"

//setup for language State
import useTranslation from "../../hooks/useTranslation";
import RadioModal from "./RadioModal"

const SearchContainer = styled.div`
    height: "100%";

    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: ${(props) => props.$allwaysOn ? 'block' : 'none'};
    }
`

const ConnectControl = ({ 
    data, 
    onSelect, 
    placeholder, 
    valueName, 
    entity, 
    modalTitle, 
    modalOpacity = 0.5, 
    isRed, 
    $allwaysOn, 
    menuItemField, 
    modalType = 'searchList', 
    radioGroups, 
    options,
    persistValue,
    testId 
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const searchContainer = useRef(null);
    const { t } = useTranslation()
    const inputRef = useRef(null);

    //Hide & Show the Menu
    const showModal = () => { 
        if (inputRef.current) {
            inputRef.current.blur(); // Blurs input to prevent keyboard pop-up
        }
        setIsVisible(true)
    }
    const hideModal = () => setIsVisible(false);
  
    return (
        <SearchContainer ref={searchContainer} $allwaysOn={$allwaysOn}>
            <Form.Group >
                <ConnectInput
                    type="text"
                    valueName={valueName || ''}
                    name={`${entity}Name_${Math.random().toString(36).substr(2, 9)}`}
                    showModal={showModal}
                    placeholder={t(placeholder)}
                    isRed={isRed}
                    autoComplete={`${entity}Name_${Math.random().toString(36).substr(2, 9)}`}
                    ref={inputRef}
                    readonly
                    pointerEvents="none"
                    testId={testId}
                />
            </Form.Group>
            {isVisible && (modalType === 'searchList') && 
                <SelectModal_m 
                    isVisible={isVisible} 
                    setIsVisible={setIsVisible} 
                    data={data}
                    valueName={valueName} 
                    onSelect={onSelect} 
                    entity={entity} 
                    menuItemField={menuItemField}
                    modalTitle={modalTitle} 
                    modalOpacity={modalOpacity}
                    options={options}
                    persistValue={persistValue}
                    testId={testId}
                />
            }
            {isVisible && (modalType === 'radioGroup') && 
                <RadioModal 
                    isVisible={isVisible} 
                    setIsVisible={setIsVisible} 
                    data={data} 
                    radioGroups={radioGroups}
                    valueName={valueName} 
                    onSelect={onSelect} 
                    entity={entity} 
                    menuItemField={menuItemField}
                    modalTitle={modalTitle} 
                    modalOpacity={modalOpacity}
                    options={options}
                    persistValue={persistValue}
                    testId={testId}
                />
            }
        </SearchContainer>
    );
};

export default ConnectControl;
