import { useRef, useEffect } from 'react'
import Modal from './Modal'
import { Button } from '../generic/Core'
import useTranslation from '../../hooks/useTranslation'
import { FlexBox } from '../generic/Layout'
import { AlertIcon } from '../toast/Alert'
import {P} from '../generic/Core'
import styled from 'styled-components'

const AlertTitle = styled.h3`
  margin-top: 0.25rem;
  margin-bottom: 0rem;
`

const successAlertColor = '#0f5132';
const warningAlertColor = '#783305';
const infoAlertColor = '#0d457a';
const errorAlertColor = '#842029';

const successAlertBg ='#d1e7dd';
const warningAlertBg = '#FEEBC8';
const infoAlertBg = '#bee3f8';
const errorAlertBg = '#f8d7da';

const successAlertBorderColor = '#badbcc';
const warningAlertBorderColor = '#f5ddb0';
const infoAlertBorderColor = '#a9daf6';
const errorAlertBorderColor = '#f5c2c7';


const colorScheme = {
  success: {
    color: successAlertColor,
    bg: successAlertBg,
    borderColor: successAlertBorderColor
  },
  warning: {
    color: warningAlertColor,
    bg: warningAlertBg,
    borderColor: warningAlertBorderColor
  },
  info: {
    color: infoAlertColor,
    bg: infoAlertBg,
    borderColor: infoAlertBorderColor
  },
  error: {
    color: errorAlertColor,
    bg: errorAlertBg, 
    borderColor: errorAlertBorderColor
  }
}



function Alert({ title, message, onClose, type = 'warning' }) {
  const { t } = useTranslation()
  const closeBtnRef = useRef(null)

  const colors = colorScheme[type]

  useEffect(() => {
    // Focus the close button when the alert is shown
    if (closeBtnRef.current) {
      closeBtnRef.current.focus()
    }
  }, [])

  return (
    <Modal $color={colors.color} $bg={colors.bg} $border={colors.borderColor} setShow={onClose} show={true} mode=''>
      <Modal.Header $color={colors.color} setShow={onClose}>
        <FlexBox>
          <AlertIcon type={type} />
          <AlertTitle>{t(title)}</AlertTitle>
        </FlexBox>
      </Modal.Header>
      <Modal.Body>
        <P>{t(message)}</P> 
      </Modal.Body>
      <Modal.Footer $color={colors.color}>
        <Button 
          ref={closeBtnRef} 
          onClick={onClose}
          data-testid="alert-close-button"
          $variant = 'custom'
          $bg = {colors.color}
          $color = {colors.bg}
        >
          {t('all.form.button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Alert
