import { title } from "process"

const validationLogicData = {
    stockShipment: {
        book: [
            {
                operation: 'compareSum',
                onFail:
                {
                    title: 'inventory.shipment.book.error.title',
                    msg: 'inventory.shipment.book.error.location',
                },
                target: {
                    type: "array",
                    array: "lines",
                    field: "itemQuantity"
                },
                condition: {
                    type: 'subArray',
                    array: 'lines',
                    subArray: 'locations',
                    field: 'quantity'
                },
            },
            {
                operation: 'compareSum',
                onFail: {
                    title: 'inventory.shipment.book.error.title',
                    msg: 'inventory.shipment.book.error.reservation',
                },
                target: {
                    type: "array",
                    array: "lines",
                    field: "itemQuantity"
                },
                condition: {
                    type: 'array',
                    array: 'reservations',
                    field: 'quantity'
                },
            },

        ],
        delete: [
            {
                operation: 'emptyArray',
                onFail:
                {
                    msg: 'inventory.shipment.delete.error.location',
                    title: 'inventory.shipment.delete.error.title',
                },
                condition: {
                    type: 'array',
                    array: 'reservations',
                },
            }
        ]
    },
    product: {
        delete: [
            {
                operation: 'emptySubCollection',
                onFail: {
                    msg: 'product.delete.error.location',
                    title: 'product.delete.error.title',
                },
                condition: {
                    collection: 'product',
                    subCollection: 'itemLocation',
                }
            }
        ]
    },
    location: {
        delete: [
            {
                operation: 'emptyArray',
                onFail: {
                    msg: 'location.delete.error.inventory',
                    title: 'location.delete.error.title',
                },
                condition: {
                    type: 'array',
                    array: 'inventory'
                }
            },
            {
                operation: 'emptyArray',
                onFail: 'location.delete.error.reservation',
                condition: {
                    type: 'array',
                    array: 'reservations'
                }
            }
        ]
    },
    rack: {
        delete: [
            {
                operation: 'emptyCollection',
                onFail: {
                    title: 'rack.delete.error.title',
                    msg: 'rack.delete.error.location'
                },
                condition: {
                    type: 'collection',
                    collection: 'location',
                    filter: {
                        field: 'rackId',
                        value: 'id'
                    }
                }
            },
        ]
    },
    hall: {
        delete: [
            {
                operation: 'emptyCollection',
                onFail: {
                    title: 'hall.delete.error.title',
                    msg: 'hall.delete.error.rack',
                },
                condition: {
                    type: 'collection',
                    collection: 'rack',
                    filter: {
                        field: 'hallId',
                        value: 'id'
                    }
                }
            },
        ]
    },
    warehouse: {
        delete: [{
            operation: 'emptyCollection',
            onFail: {
                title: 'warehouse.delete.error.title',
                msg: 'warehouse.delete.error.rack'
            },
            condition: {
                type: 'collection',
                collection: 'rack',
                filter: {
                    field: 'warehouseId',
                    value: 'id'
                }
            }
        },]
    }

}

export default validationLogicData