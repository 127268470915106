const emptyCollection = async (target, condition, data, getData) => { 
    //console.log('running emptyCollection')
    const {filter} = condition

    let endpoint = `${condition.collection}?filter=${filter.field}:${data[filter.value]}&noPagination=true`

    const dataFetched = await getData(endpoint)
    const totalRecords = (dataFetched?.data || []).length;
    
    if (totalRecords === 0) {
        return({valid: true})
    }

    return({valid: false})

}

export default emptyCollection