import { pageViewData } from "../../model/components/pageViewData"
import CardView from "../cardView/CardView"
import TabView from "./layouts/TabView"
import FilteredView from "./layouts/FilteredView"
import MultiView from "../multiView/MultiView"

const PageLayout = ({ type, options, dispatch }) => {
    const config = pageViewData[type]

    //console.log('[PageLayout] config: ', config)

    //console.log('re-rendering PageLayout')
    return (
        <>
            {config.type === 'simpleCard' && <CardView type={config.viewType} options={options} dispatch={dispatch}
                />}

            {config.type === 'tabView' && <TabView type={config.viewType} options={options} dispatch={dispatch}
                 />}

            {config.type === 'filteredView' && <FilteredView options={options}
                dispatch={dispatch} config={config} />}

            {config.type === 'multiView' && <MultiView type={config.viewType} options={options}
                dispatch={dispatch} config={config} />}

        </>
    )

}

export default PageLayout