const getComparisonArray = (data, condition) => {
    if (!data || !condition || !condition.array) return [];

    const mainArray = data[condition.array];
    if (!Array.isArray(mainArray)) return [];

    if (condition.type === 'array') {
        return mainArray;
    }

    if (condition.type === 'subArray' && condition.subArray) {
        return mainArray.flatMap(item => item[condition.subArray] || []);
    }

    return [];
};

const sumField = (array, field) => {
    if (!Array.isArray(array)) return 0;
    return array.reduce((sum, item) => sum + (item[field] || 0), 0);
};

const compareSum = (target, condition, data) => {
    //console.log('running compareSum')

    const targetArray = data[target.array]
    const comparisonArray = getComparisonArray(data, condition)

    const totalTarget = sumField(targetArray, target.field)
    const totalComparison = sumField(comparisonArray, condition.field)

    if (totalTarget === totalComparison) {
        return ({ valid: true })
    }


    return ({ valid: false })
}

export default compareSum