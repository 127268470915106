import {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import * as RiIcons from 'react-icons/ri'
import useTranslation from '../../hooks/useTranslation'

const iconClosed = <RiIcons.RiArrowDownSFill />
const iconOpened = <RiIcons.RiArrowUpSFill />

const SideBarLink = styled(Link)`
    display: flex;
    color: ${({theme}) => theme.nav.background} ;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 1rem;
    &:hover {
        background: ${({theme}) => theme.nav.active};
        border-left: 4px solid ${({theme}) => theme.nav.stripe};
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
    }

`

const SidebarLabel = styled.span`
    margin-left: 0.5rem;
    color: ${({theme}) => theme.nav.font};
`

const SidebarSubLabel = styled.span`
    margin-left: 0.5rem;
    color: ${({theme}) => theme.nav.font};;
    font-size: 1rem;
    padding-right: 0.25rem;
`

const DropdownLink = styled(Link)`
    background: ${({theme}) => theme.nav.background};
    height: 60px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({theme}) => theme.nav.font} ;
    &:hover {
        background: ${({theme}) => theme.nav.active};
        border-left: 4px solid ${({theme}) => theme.nav.stripe};
        cursor: pointer;
    }
    font-size: 1.1rem;
    &:hover {
        background: ${({theme}) => theme.nav.active};
        cursor: pointer;
    }
`

const SBSubMenu = ({item, handleClick}) =>  {
    const [subnav, setSubnav] = useState()
    const showSubnav = () => {
        setSubnav(!subnav)
    }

    const clickItem = (e) => {
        item.subNav && showSubnav()
        !item.subNav && handleClick(e)
    }

    const {t} = useTranslation()
    const path = window.location.pathname

    return (
    <>
        <SideBarLink to={item.path || path} onClick={ clickItem } >
            <div>
                {item.icon}
                <SidebarLabel data-testid={`sidebar.${item.title}`}>{t(item.title)}</SidebarLabel>
            </div>
            <div>
                {item.subNav && subnav 
                ? iconOpened 
                : item.subNav 
                ? iconClosed 
                : null}
            </div>
        </SideBarLink>
         {(item.subNav) ? subnav && item.subNav.map( 
            (item, index) => {
                return (
                    <DropdownLink to={item.path} key={index} onClick={handleClick}>
                        {item.icon}
                        <SidebarSubLabel data-testid={`sidebar.${item.title}`} >{t(item.title)}</SidebarSubLabel>
                    </DropdownLink>
                )
            }
         ) : <></> }
    </>
  )
}

export default SBSubMenu