import ListView from "../../../components/listView/ListView";
import Page from "../../../components/pageView/Page";
import WidgetPage from "../../../components/widgetPage/WidgetPage";

const routesBank = [
  {
    path: "/bank",
    element: WidgetPage,
    props: {
      type: "bank",
      draggable: false,
      resizable: false,
    },
  },
  {
    path: "/bank/account",
    element: ListView,
    props: {
      type: "paymentAccountList",
      draggable: false,
      resizable: false,
    },
  },
  {
    path: "/bank/account/:id",
    element: Page,
    props: {
      type: "paymentAccountMultiPage",
      options: { mode: "edit", load: true, collapse: true },
    }
  },
  {
    path: "/bank/account/new",
    element: Page,
    props: {
      type: "paymentAccountPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/bank/account/:id/transaction/:id2",
    element: Page,
    props: {
      type: "paymentTransactionPage",
      options: { mode: "edit", load: true },
    },
  },
  {
    path: "/bank/account/:id/transaction/new",
    element: Page,
    props: {
      type: "paymentTransactionPage",
      options: { mode: "new", load: false },
    },
  },
];

export default routesBank;
