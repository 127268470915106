import validationLogicData from "../model/logic/validationLogicData"
import compareSum from "./validate/compareSum"
import emptyArray from "./validate/emptyArray"
import emptySubCollection from "./validate/emptySubCollection"
import emptyCollection from "./validate/emptyCollection"

const runValidationLogic = async (entity, operation, data, getData) => {
    //console.log('<runValidationLogic> Running Validation Logic.....')
    let response = { valid: true, error: 'error.validation.generic' }
    let result
    const config = validationLogicData?.[entity]?.[operation];
    if (!config) return response

    response = { valid: false, error: 'error.validation.generic' }

    if (config.length) {
        for (const rule of config) {
            const { operation, onFail, target, condition } = rule

            if (operation === 'compareSum') {
                result = compareSum(target, condition, data)
            }

            if (operation === 'emptyArray') {
                result = emptyArray(condition, data)
            }

            if (operation === 'emptySubCollection') {
                result = await emptySubCollection(target, condition, data, getData)
            }

            if (operation === 'emptyCollection') {
                result = await emptyCollection(target, condition, data, getData)
            }
            response.valid = result.valid

            if (!result.valid) {
                response.error = onFail.msg
                response.title = onFail.title
                break
            }
        }
    }
    return response
}
export default runValidationLogic