const emptySubCollection = async (target, condition, data, getData) => {
    //console.log('running emptySubCollection')

    let endpoint = `${condition.collection}/${data.id}/${condition.subCollection}?noPagination=true` 

    const dataFetched = await getData(endpoint)
    const totalRecords = (dataFetched?.data || []).length;
   
    if (totalRecords === 0) {
        return({valid: true})
    }

    return({valid: false})

}           

export default emptySubCollection