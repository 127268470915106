import { snapshot } from 'valtio';
import useProfile from './useProfile';

const useLocale = () => {
  const { pState } = useProfile()
  const profile = snapshot(pState)
  const currentOrgLang = profile.currentOrgLanguage


  const decimalSeparator = (1.1).toLocaleString(currentOrgLang).substring(1, 2);

  function lf(value, min = 0, max = 20) {

    if (typeof value !== 'number' || isNaN(value)) {
      return value;
    }

    return value.toLocaleString(profile.currentOrgLanguage, {
      minimumFractionDigits: min || 0,
      maximumFractionDigits: max || 20// set a high maximum to avoid rounding
    })
  }

  const ld = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString(profile.currentOrgLanguage);
  };

  const toNumber = (str) => {
    if (!str) return 0; // Return 0 for undefined, null, or empty string
    if (typeof str === 'number') return str; // Return as is if already a number
    
    const formatter = new Intl.NumberFormat(currentOrgLang);
    const parts = formatter.formatToParts(1234.56); // Get decimal separator
  
    const decimalSeparator = parts.find(part => part.type === "decimal")?.value || ".";
  
    return Number(str.replace(new RegExp(`[^0-9-${decimalSeparator}]`, "g"), "").replace(decimalSeparator, "."));
  };



  return { lf, ld, currentOrgLang, decimalSeparator, toNumber }

}


export default useLocale
