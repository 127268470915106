import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import useApi from '../../hooks/useApi';
import { RadioGroup } from '../generic/RadioGroup';
import formatNumberWithZeros from '../../../../common/utils/format/formatNumbersWithZeros.js';
import styled from 'styled-components';

const RadioGroupTitle = styled.h4`
  margin-block: 0.5rem;
`;

const SubGroupContainer = styled.div`
  margin-top: 1rem;
`;

const RadioGroupSelector = ({ 
  entity, 
  filter = '', 
  valueField = 'id', 
  labelField = 'name', 
  icon,
  parentValue,
  value,
  onChange,
  columns,
  filterValues = {},
  subRadio,
  title,
  t,
  selectedValues = {},
  testId
}) => {
  const { getMe } = useApi();

  console.log('testId:', testId)

  // Generate options for subRadio groups
  const generateNumberedOptions = (total) => {
    if (!total) return [];
    
    return Array.from({ length: total }, (_, i) => {
      const number = i + 1;
      const formattedNumber = formatNumberWithZeros(number, total);
      return {
        value: formattedNumber,
        label: formattedNumber,
        icon: icon
      };
    });
  };

  // Construct filter string from filter configuration
  const constructFilterString = useMemo(() => {
    if (!filter || !Array.isArray(filter)) return '';

    const filterParams = Object.entries(filterValues)
      .map(([param, value]) => value ? `${param}:${value}` : '')
      .filter(Boolean);

    return filterParams.length > 0 ? filterParams.join(';') : '';
  }, [filter, filterValues]);

  // First, fetch the parent data if needed
  const { data: parentData } = useQuery(
    ['parent', entity],
    () => getMe(entity.split('/')[0]),
    {
      enabled: Boolean(entity?.includes(':id')),
      staleTime: 30000,
    }
  );

  // Construct the endpoint based on entity type and parent data
  const endpoint = useMemo(() => {
    if (!entity) return '';
    
    if (entity.includes(':id') && parentValue) {
      const [baseEntity, , subEntity] = entity.split('/');
      const parent = parentData?.data?.find(item => item.code === parentValue);
      if (!parent) return null;
      
      const endpoint = `${baseEntity}/${parent.id}/${subEntity}`;
      return constructFilterString ? `${endpoint}?filter=${constructFilterString}` : endpoint;
    }
    
    return constructFilterString ? `${entity}?filter=${constructFilterString}` : entity;
  }, [entity, parentValue, parentData, constructFilterString]);

  // Fetch the actual data
  const { data, isLoading, error } = useQuery(
    [entity, parentValue, constructFilterString],
    () => getMe(endpoint),
    {
      enabled: Boolean(entity && (!entity.includes(':id') || (parentValue && endpoint))),
      staleTime: 30000,
    }
  );

  if (entity?.includes(':id') && !parentValue) {
    return null;
  }

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  // Get options from API data
  const options = data?.data?.map(item => ({
    value: item[valueField],
    label: item[labelField],
    icon: icon,
    originalData: item // Keep the original item data for subgroups
  })) || [];

  // Find the selected item's data
  const selectedItem = value ? options.find(opt => opt.value === value)?.originalData : null;

  // Render subgroups if they exist and main group has a value
  const renderSubGroups = () => {
    if (!subRadio || !value || !selectedItem) return null;

    return (
      <SubGroupContainer>
        {subRadio.map((subGroup, idx) => {
          // Get the total from the selected item's data using the valueField from subGroup
          const total = selectedItem[subGroup.valueField];
          if (!total) return null;

          const subOptions = generateNumberedOptions(total);
          if (subOptions.length === 0) return null;

          return (
            <div key={idx}>
              <RadioGroupTitle>{t(subGroup.title)}</RadioGroupTitle>
              <RadioGroup
                value={selectedValues[subGroup.id]}
                onChange={(newValue) => onChange(newValue, subGroup.id)}
                options={subOptions}
                columns={subGroup.columns || 4}
                groupId={subGroup.idPrefix}
                testId={`${subGroup.id}`}
              />
            </div>
          );
        })}
      </SubGroupContainer>
    );
  };

  // Only render if there are options
  if (options.length === 0) return null;

  return (
    <>
      <RadioGroupTitle>{t(title)}</RadioGroupTitle>
      <RadioGroup
        value={value}
        onChange={onChange}
        options={options}
        columns={columns}
        groupId={entity}
        testId={testId}
      />
      {renderSubGroups()}
    </>
  );
};

export default RadioGroupSelector;
