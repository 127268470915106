import { Icon_Boxes, Icon_Delete, Icon_Money } from "../../styles/icons"

export const modalGroupsData = {
    invoiceDesign: {
        modalGroups: [
            {
                label: 'invoice.form.font',
                type: 'dataList',
                id: 'invoiceFont',
                display: 'invoiceFont',
                placeholder: 'invoice.form.font.placeholder',
                entity: 'fonts',
                modalTitle: 'invoice.form.font.modal.title',
                modalOpacity: 0,
                isRequired: true,
                defaultType: 'value',
                defaultValue: 'Helvetica',
                valueMap: {
                    'invoiceFont': 'id',
                }
            },
            {
                label: 'invoice.form.layout',
                type: 'dataList',
                id: 'layout',
                display: 'layoutName',
                placeholder: 'invoice.form.layout.placeholder',
                entity: 'layouts',
                modalTitle: 'invoice.form.layout.modal.title',
                modalOpacity: 0,
                isRequired: true,
                defaultType: 'value',
                defaultValue: 'default',
                valueMap: {
                    'layout': 'id',
                    'layoutName': 'name',
                },
            }
        ]
    },
    inventoryShipmentDesign: {
        modalGroups: [
            {
                label: 'inventory.shipment.form.font',
                type: 'dataList',
                id: 'font',
                display: 'font',
                placeholder: 'inventory.shipment.form.font.placeholder',
                entity: 'fonts',
                modalTitle: 'inventory.shipment.form.font.modal.title',
                modalOpacity: 0,
                isRequired: true,
                defaultType: 'value',
                defaultValue: 'Helvetica',
                valueMap: {
                    'font': 'id',
                },
            },
            {
                label: 'inventory.shipment.form.layout',
                type: 'dataList',
                id: 'layout',
                display: 'layoutName',
                placeholder: 'inventory.shipment.form.layout.placeholder',
                entity: 'layouts',
                modalTitle: 'inventory.shipment.form.layout.modal.title',
                modalOpacity: 0,
                isRequired: true,
                defaultType: 'value',
                defaultValue: 'default',
                valueMap: {
                    'layout': 'id',
                    'layoutName': 'name',
                },
            }
        ]
    },
    invoiceOptions: {
        modalGroups: [
            {
                type: "select",
                id: "currency",
                display: "currency",
                label: "invoice.form.currency",
                entity: "currency",
                modalTitle: "invoice.form.currency.modal.title",
                valueMap: {
                    currency: "id",
                    currencyName: "name",
                },
                placeholder: "invoice.form.currency.placeholder",
                options: { withHeaders: true, displayId: true },
                isRequired: true,
                defaultType: "select",
            },
            {
                type: "select",
                id: "language",
                display: "languageName",
                label: "invoice.form.language",
                entity: "language",
                modalTitle: "invoice.form.language.modal.title",
                valueMap: {
                    language: "id",
                    languageName: "name",
                },
                placeholder: "invoice.form.language.placeholder",
                options: { withHeaders: false },
                isRequired: true,
                defaultType: "select",
            },
        ],
    },
    invoicePayments: {
        modalGroups: [
            {
                type: "list",
                id: 'paymentDate',
                section: {
                    meta: {
                        type: 'lines',
                        linesArray: 'payments',
                        linesAddAction: 'invoice.form.payment.add.item',
                        name: 'invoicePaymentsLines',
                        width: '800px',
                        nFields: { 'paymentAmount': true }
                    },
                    modal: {
                        title: 'invoice.form.payments.modal.title',
                        body: {
                            description: 'invoice.form.payments.modal.body',
                            params: ['numPayments'],
                        },
                        footer: {
                            description: 'invoice.form.payments.modal.footer',
                            params: ['$totalGrossSalesAmount', 'currency'],
                            sumField: 'paymentAmount',
                            warning: {
                                tagVariant: 'danger',
                                tagLabel: 'invoice.form.payments.modal.warning.label',
                                condition: 'totalGrossSalesAmount',
                                message: 'invoice.form.payments.modal.warning.message',
                                params: ['currency']
                            }
                        }
                    },
                    groups: [
                        {
                            type: 'group',
                            focus: true,
                            fields: [
                                {
                                    type: 'date',
                                    id: 'paymentDate',
                                    label: 'invoice.form.paymentDate',
                                    placeholder: 'invoice.form.paymentDate.placeholder',
                                    defaultType: 'date'
                                },
                                {
                                    type: 'number',
                                    id: 'paymentAmount',
                                    placeholder: 'invoice.form.netPrice',
                                    label: 'invoice.form.paymentAmount',
                                    variant: 'amount',
                                    isRequired: true,
                                    decimals: 2,
                                    defaultType: 'number',
                                    defaultValue: 1,
                                },

                            ]
                        }
                    ],
                    buttons: [
                        {
                            type: 'delete',
                            size: 'icon',
                            icon: Icon_Delete,
                            variant: 'danger',
                            label: 'all.form.button.delete',
                            action: 'delete'
                        },
                        {
                            type: 'close',
                            variant: 'primary',
                            label: 'all.form.button.save',
                            action: 'save'
                        }
                    ],
                    display: {
                        type: 'lines',
                        linesArray: 'payments',
                        emptyMessage: 'invoice.form.payment.missing.message',
                        emptyField: 'paymentAmount',
                        editPos: '25%',
                        columns: [
                            {
                                size: 7,
                                grow: '70%',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'niceDate',
                                        value: 'paymentDate'
                                    }
                                ]
                            },
                            {
                                size: 3,
                                grow: '30%',
                                align: 'end',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'amount',
                                        value: 'paymentAmount'
                                    },
                                    {
                                        size: 'regular',
                                        type: 'currency'
                                    }
                                ],
                            }
                        ]
                    }
                },
            }
        ],
    },
    stockLocations: {
        modalGroups: [
            {
                type: "list",
                id: 'allocatedQuantity',
                section: {
                    meta: {
                        type: 'lines',
                        linesArray: 'locations',
                        linesAddAction: 'inventory.shipment.form.lines.locations.add.item',
                        name: 'stockShipmentLocationLines',
                        width: '800px',
                        nFields: { 'paymentAmount': true }
                    },
                    modal: {
                        title: 'inventory.shipment.form.lines.locations.modal.title',
                        body: {
                            description: 'inventory.shipment.form.lines.locations.modal.body',
                            params: ['allocatedQuantity', 'itemQuantity'],
                        },
                        footer: {
                            description: 'inventory.shipment.form.lines.locations.modal.footer',
                            params: ['itemQuantity'],
                            //sumField: This is the field that will need to be summed up
                            sumField: 'quantity',
                            warning: {
                                tagVariant: 'yellow',
                                tagLabel: 'inventory.shipment.form.lines.locations.modal.warning.label',
                                condition: 'itemQuantity',
                                message: 'inventory.shipment.form.lines.locations.modal.warning.message',
                                params: []

                            }
                        }
                    },
                    groups: [
                        {
                            type: 'group',
                            focus: true,
                            fields: [
                                {
                                    type: 'connect',
                                    id: 'locationId',
                                    display: 'locationId',
                                    label: 'inventory.shipment.form.lines.location',
                                    placeholder: 'inventory.shipment.form.lines.location.placeholder',
                                    entity: 'location',
                                    valueMap: {
                                        'locationId': 'id',
                                        'warehouseId': 'warehouseId',
                                        'warehouseCode': 'warehouseCode',
                                        'hallId': 'hallId',
                                        'hallCode': 'hallCode',
                                        'rackId': 'rackId',
                                        'rackCode': 'rackCode',
                                        'level': 'level',
                                        'position': 'position',
                                        'unitId': 'unitId',
                                        'unitName': 'unitName',
                                    },
                                },
                                {
                                    type: 'number',
                                    id: 'quantity',
                                    placeholder: 'inventory.shipment.form.lines.location.quantity.placeholder',
                                    label: 'inventory.shipment.form.lines.location.quantity',
                                    variant: 'quantity',
                                    isRequired: true,
                                    decimals: 2,
                                    defaultType: 'number',
                                    defaultValue: 1,
                                },

                            ]
                        }
                    ],
                    buttons: [
                        {
                            type: 'delete',
                            size: 'icon',
                            icon: Icon_Delete,
                            variant: 'danger',
                            label: 'all.form.button.delete',
                            action: 'delete'
                        },
                        {
                            type: 'save',
                            variant: 'primary',
                            label: 'all.form.button.save',
                            action: 'save'
                        }
                    ],
                    display: {
                        type: 'lines',
                        linesArray: 'payments',
                        emptyMessage: 'invoice.form.payment.missing.message',
                        emptyField: 'locationId',
                        editPos: '25%',
                        columns: [
                            {
                                size: 7,
                                grow: '70%',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'field',
                                        value: 'locationId'
                                    }
                                ]
                            },
                            {
                                size: 3,
                                grow: '30%',
                                align: 'end',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'field',
                                        value: 'quantity'
                                    },
                                    {
                                        size: 'title',
                                        type: 'field',
                                        value: 'unitName'
                                    }
                                ],
                            }
                        ]
                    }

                },
            }
        ],
    },
    selectLocations: {
        modalGroups: [
            {
                type: "multiSelect",
                section: {
                    meta: {
                        load: true,
                        testId: 'shipment.lines.section.line'
                    },
                    modal: {
                        header: {
                            description: 'inventory.shipment.form.lines.select.locations.body',
                            params: ['allocatedQuantity', 'itemQuantity'],
                        },
                        body: {
                            type: 'multiList',
                            listItems: 'location',
                            columns: [
                                { title: 'inventory.list.title.location', field: 'locationId', flex: "2", margin: '0', check: true },
                                { title: 'inventory.list.title.quantity', field: 'availableQuantity', flex: "1", margin: '0', deco: 'unitName', tag: true }
                            ],
                            emptyMessage: 'inventory.shipment.form.lines.select.locations.no.data',
                            endpoint: 'product/:id/itemLocation',
                            endpointId: 'itemId',
                            constraint: 'itemQuantity',
                            filter: 'reservedQuantity',
                            sumField: 'quantity',

                        },
                        footer: {
                            description: 'inventory.shipment.form.lines.select.locations.footer',
                            params: ['allocatedQuantity', 'itemQuantity'],
                            //sumField: This is the field that will need to be summed up
                            sumField: 'quantity',
                            warning: {
                                tagVariant: 'yellow',
                                tagLabel: 'inventory.shipment.form.lines.locations.modal.warning.label',
                                condition: 'itemQuantity',
                                message: 'inventory.shipment.form.lines.locations.modal.warning.message',
                                params: []
                            }
                        },
                        onUpdate: {
                            linesArray: 'locations',
                            valueMap: {
                                'locationId': 'id',
                                'warehouseId': 'warehouseId',
                                'warehouseCode': 'warehouseCode',
                                'hallId': 'hallId',
                                'hallCode': 'hallCode',
                                'rackId': 'rackId',
                                'rackCode': 'rackCode',
                                'level': 'level',
                                'position': 'position',
                                'unitId': 'unitId',
                                'unitName': 'unitName',
                            },
                        }
                    },
                }
            }
        ],
    },
    inventoryShipmentReservations: {
        modalGroups: [
            {
                type: "tabList",
                section: {
                    meta: {
                        name: "shipment.reservation",
                        load: false,
                        calculate: {
                            arrayPath: ['lines', "*", "locations", "*"],
                            data2Copy: ['lines.itemName']

                        },
                        tabList: 'reservationTabView',
                        linesArray: 'locations',
                    },
                    tabs: [
                        {
                            id: 'unreservedTab',
                            description: 'inventory.shipment.form.reservation.unReserved.description',
                            emptyMessage: 'inventory.shipment.form.reservation.unReserved.no.data',
                            nav: {
                                path: '/inventory/location/',
                                pathId: 'locationId',    
                            },
                            path: '/inventory/location/',
                            pathId: 'locationId',
                            filter: {
                                field: 'reservedQuantity',
                                value: 'isUndefined'
                            },
                            columns: [
                                { title: 'inventory.list.title.product', field: 'itemName', flex: "3", margin: '0 0.75rem 0 0' },
                                { title: 'inventory.list.title.location', field: 'locationId', flex: "4", margin: '0' },
                                { title: 'inventory.list.title.quantity', field: 'quantity', flex: "1", margin: '0' }
                            ],
                            action: {
                                buttonLabel: 'inventory.shipment.form.reservation.unReserved.buttonLabel',
                                endpoint: 'stockShipment/:id/reserve',
                                name: 'reserve',
                            }
                        },
                        {
                            id: 'reservedTab',
                            description: 'inventory.shipment.form.reservation.reserved.description',
                            emptyMessage: 'inventory.shipment.form.reservation.reserved.no.data',
                            nav: {
                                path: '/inventory/reservation/',
                                pathId: 'reservationId',    
                            },
                            path: '/inventory/reservation/',
                            pathId: 'reservationId',
                            filter: {
                                field: 'reservedQuantity',
                                value: 'isNotUndefined'
                            },
                            columns: [
                                { title: 'inventory.list.title.product', field: 'itemName', flex: "3", margin: '0 0.75rem 0 0' },
                                { title: 'inventory.list.title.location', field: 'locationId', flex: "4", margin: '0' },
                                { title: 'inventory.list.title.quantity', field: 'quantity', flex: "1", margin: '0' }
                            ],
                            action: {
                                buttonLabel: 'inventory.shipment.form.reservation.reserved.buttonLabel',
                                endpoint: 'stockShipment/:id/unreserve',
                                name: 'unreserve',
                            }
                        }
                    ]
                }
            }
        ],
    }
}
