import { TextControl } from "./Core";
import useFormatting from "../../hooks/useFormatting";
import IconButton from "./IconButton";
import styled from "styled-components";
import { Icon_Search } from "../../styles/icons";
import useTranslation from "../../hooks/useTranslation";
import generateEndpoint from "../../utils/generateEndpoint";
import { useNavigate } from "react-router-dom";

const FlexWrap = styled.div`
    display: flex;
    gap: 0.25rem;
`

const ReadOnly = ({ value, fieldId, type, displayType = '', name, placeholder, className, readOnly, action, testId }) => {

    const { formatString } = useFormatting()
    const { tState } = useTranslation()
    const { currentSlug } = tState
    const navigate = useNavigate()

    const result = formatString(value, displayType)
    let pathId

    if (action) {
        action.id === 'fieldValue' ? pathId = value : pathId = fieldId
    }


    const clickAction = () => {
        const url = `/${currentSlug}${action.path}`
        const endpoint = generateEndpoint(url, pathId)
        navigate(endpoint, { replace: true })
        //alert(`Action Activated for this endpoint: ${endpoint}`)
    }


    return (
        <FlexWrap>
            <TextControl type={type} name={name} value={result} placeholder={placeholder} className={className} readOnly={readOnly} />
            {action && <IconButton icon={Icon_Search} $rounded $variant='primary' $size='2rem' clickAction={clickAction} data-testid={testId} />
            }        </FlexWrap>
    )
}

export default ReadOnly