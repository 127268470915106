import React, { useState } from 'react';
// NPM dependencies
import DropDown from '../generic/DropDown';

// API Imports
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

//Import Read and Save to Local Store
import { saveToLS, getFromLS } from '../../utils/localStore/localStore';
import ClientVersion from '../../Version';

//Import Auth Hook
import useAuth from "../../hooks/useAuth"

//Import Translation Hook
import useTranslation from '../../hooks/useTranslation';

//Import the org Switcher functionality from useOrg
import useOrg from '../../hooks/useOrg';
import useProfile from '../../hooks/useProfile';
import { useSnapshot } from 'valtio';
import { Switcher } from '../generic/Switcher';

//Import the popUp things
import { PopUp } from '../modal/PopUp';
import { modalData } from '../../model/components/modalData';
let config = modalData['orgSwitch']

const checkVersion = (version) => {
  const saved = localStorage.getItem('version');
  if (!saved) {
    saveToLS('version','client', version)
    return false
  }

  const savedVersion = JSON.parse(saved)
  console.log('Currently on version: ', savedVersion.client)
  
  if (savedVersion.client !== version && version !== ClientVersion) {
    console.log('New Version available: ', version)
    return true
  }

  return false
}



function OrgSwitcher() {
  const [show, setShow] = useState(false)

  const { getMe } = useApi()
  const { Auth } = useAuth()
  const { switchOrg } = useOrg()
  const { pState } = useProfile()
  const profile = useSnapshot(pState)

  const { t } = useTranslation()

  const getData = async () => {
    const resource = `userOrganization`
    const response = await getMe(resource)

    if (response?.meta?.clientVersion) {
      const update = checkVersion(response.meta.clientVersion)
      if (update) {
        pState.newVersion = update
      } else {
        pState.newVersion = false
      }
    }

    if (response && response?.data) {
      saveToLS('orgSwitcher','data',response.data)
      return response.data
    } else { 
      const localData = getFromLS('orgSwitcher','data')
      return localData
    }
  }

  //This is the function run by selecting an option on Switcher
  const handleSelect = async (option) => {
    await switchOrg(option.id)
    //Implement new Modal
    config.params[0] = pState.currentOrgName
    setShow(true)
  }

  //Retrieve the data from the API, Notice that order is passed as second param
  const result = useQuery('userOrgs', getData)

  if (result.isLoading) {
    return <DropDown>Loading...</DropDown>
  }

  if (result.isError) {
    return <DropDown>Error...</DropDown>
  }

  if (result.isSuccess) {
    if (profile.currentOrgName === '') {

    }

    //Collect the Orgs to display

    const orgs = Array.isArray(result.data) ? result.data.map((item, key) => {
      return (
        //The Option array passed to switcher expects: type, id and name.
        { type: 'item', id: item.id, name: item.name })
    }) : []


    //Create the first two Rows and add the orgs in.
    //The Option array passed to switcher expects: type, id and name.
    const userInfo = [{ type: 'inactive', name: Auth.email, id: 'email' }, { type: 'divider', id: 'div1' }]
    const LogOutAction = [{ type: 'divider', id: 'div2' }, { type: 'action', name: t('orgSwitch.logout.action'), id: 'logOutButton', path: '/' }]
    const options = [...userInfo, ...orgs, ...LogOutAction]

    return (
      <>
        <Switcher type = 'org' currentOption={profile.currentOrgName} options={options} handleSelect={handleSelect} startPos={1}></Switcher>
        <PopUp show={show} setShow={setShow} config={config} />
      </>
    )
  }
}

export default OrgSwitcher;
