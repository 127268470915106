import { useRef, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import Modal from "../modal/Modal";
import CountryMenuItem from "./CountryMenuItem";
import useTranslation from "../../hooks/useTranslation";
import { TextControl } from "../generic/Core";

//Import KeyPress Utils:
import useKeyPress from "../../hooks/useKeyPress";

const SearchField = styled(TextControl)`
`;


export const CountryFlag = styled.img`
    max-width: 2rem;
    padding-left:0.5rem;
    max-height: 1.25rem;
`

const ListGroup = styled.ul`
    --list-group-color: #212529;
    --list-group-bg: ${({ theme }) => theme.dropdown.background};;
    --list-group-border-color: rgba(0, 0, 0, 0.125);
    --list-group-border-width: 1px;
    --list-group-border-radius: 0.375rem;
    --list-group-item-padding-x: 1rem;
    --list-group-item-padding-y: 0.5rem;
    --list-group-action-color: #495057;
    --list-group-action-hover-color: #495057;
    --list-group-action-hover-bg: #f8f9fa;
    --list-group-action-active-color: #212529;
    --list-group-action-active-bg: #e9ecef;
    --list-group-disabled-color: #6c757d;
    --list-group-disabled-bg: #fff;
    --list-group-active-color: #fff;
    --list-group-active-bg: #0d6efd;
    --list-group-active-border-color: #0d6efd;
    display: flex;
    flex-direction: column;
    background-color: var(--list-group-bg);
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--list-group-border-radius);
    overflow-x: hidden;
`

const SearchResults = styled.div`
    position: absolute;
    top: 60px;
    width: calc(100% - 20px);
    max-height: calc(100% - 60px);
    overflow-y: auto;
    z-index:101;
    
    &.visible {
      visibility: visible;
    }
  
    &.invisible {
        visibility: hidden!important;
    }
  `;

const Jumper = ({ headers }) => {

    const scroll = (id) => {
        console.log('scrolled to: ', id)
        const elem = document.getElementById(id)
        console.log(elem)
        if (elem) { elem.scrollIntoView({ behavior: 'smooth' }) }
    }

    return (
        <JumpWrapper>{
            headers.map((h, i) => (
                <JumpItem key={h.id} onClick={() => { scroll(`${h.id}`) }} >{h.name}</JumpItem>
            ))}
        </JumpWrapper>
    )
}


const JumpWrapper = styled.div`
        position: absolute;
        width:10px;
        height: calc( 100% - 60px);
        right: 10px;
        top: 60px;
        z-index:2000;
    `

const JumpItem = styled.div`

    //Resizes the font-size based on the screen size. Each Font is 1.8% of Viewport Height
    font-size: calc(1.8vh);
`


const CountryModal_m = ({
    data,
    valueName,
    isVisible,
    setIsVisible,
    onSelect,
    flags
}) => {
    const { t } = useTranslation()
    const [cursor, setCursor] = useState(-1);
    const [search, setSearch] = useState("");

    const searchResultRef = useRef(null);
    const focusRef = useRef(null)


    const scrollIntoView = position => {
        searchResultRef.current.parentNode.scrollTo({
            top: position,
            behavior: "smooth"
        });
    };


    //On Load: Focus on the search Field
    useEffect(
        () => {
            focusRef.current.focus();
        }, []
    )


    useEffect(() => {
        if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
            return () => { };
        }

        let listItems = Array.from(searchResultRef.current.children);
        listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
    }, [cursor]);

    //Load the value that is already in the DB
    useEffect(() => {
        setSearch()
    }, [valueName])

    const handleSearch = () => {

    }

    const suggestions = useMemo(() => {
        if (!search) return data.list;
        setCursor(-1);
        scrollIntoView(0);

        const searchLower = search.toLowerCase();
        return data.list.filter(item =>
            (searchLower.length >= 3 && item.name.toLowerCase().includes(searchLower)) || 
            item.id.toLowerCase().includes(searchLower)
        );
    }, [data.list, search]);

    //Monitoring pressing Enter
    const enterPressed = useKeyPress('Enter');
    const escPressed = useKeyPress('Esc');

    //Keyboard events
    const keyboardNavigation = e => {
        if (e.key === "ArrowDown") {
            isVisible
                ? setCursor(c => (c < suggestions.length - 1 ? c + 1 : c))
                : showModal();
        }

        if (e.key === "ArrowUp") {
            setCursor(c => (c > 0 ? c - 1 : 0));
        }

        if (e.key === "Escape") {
            setIsVisible(false);
        }

        if (e.key === "Enter" && cursor > 0) {
            setSearch(suggestions[cursor].name);
            setIsVisible(false);
            onSelect(suggestions[cursor]);
        }
    };

    useEffect(() => {
        if (enterPressed) {
            if (cursor >= 0) {
                setSearch(suggestions[cursor].name);
                setIsVisible(false);
                onSelect(suggestions[cursor]);
            }
        }
    }, [enterPressed]);


    useEffect(() => {
        if (escPressed) {
            setIsVisible(false);
        }
    }, [escPressed]);


    return (
        <Modal setShow={setIsVisible} show={isVisible} mode={'fullscreen'} className={'fullscreen'}>
            <Modal.Header setShow={setIsVisible}>
                <h3 style={{margin: '0px'}}>{t('country.modal.title')}</h3>
            </Modal.Header>
            <Modal.Body>
                <TextControl
                    type="text"
                    value={search || ''}
                    name={'name'}
                    onChange={e => setSearch(e.target.value)}
                    onKeyDown={e => keyboardNavigation(e)}
                    autoComplete="new-country"
                    ref={focusRef}
                />
                <SearchResults>
                    <ListGroup ref={searchResultRef} id='countries-menu'>
                        {suggestions.map((item, idx) => (
                            <CountryMenuItem
                                key={item.id}
                                onSelectItem={() => {
                                    setIsVisible(false);
                                    setSearch(item.name);
                                    onSelect(item);
                                }}
                                isHighlighted={cursor === idx ? true : false}
                                {...item}
                                flag={flags[item.id]}
                            />
                        ))}
                    </ListGroup>
                </SearchResults>
                <Jumper headers={data.headers}></Jumper>
            </Modal.Body>
        </Modal>
    );
};

export default CountryModal_m;
