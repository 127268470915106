import { useState } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormGroup } from "../form/FormComponents"
import { RelativeWrapper } from "../generic/Layout"
import Triangle from "../generic/Triangle"
import ModalForm from "./ModalForm"

import generateStrings from "../../utils/generateStrings"
import processModalGroups from "../../utils/model/processModalGroups"
import { getNestedValue, setNestedValue } from "../../utils/objects/nestedValue"

const runValidation = (validation, state) => {
    const { field, operator, value } = validation

    if (operator === '===') {
        return state[field] === state[value] ? '' : `2px solid red`
    } else return ''
}



const ModalFormElem = ({ item, lineId, validated, section, entity, useStore, path = [], options }) => {
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()
    const sectionName = section?.meta?.name

    let testId = `${sectionName}.${item.control.id}`
    if (lineId !== undefined) testId = `${sectionName}.section.line.${lineId + 1}.${item.control.id}`

    //Ignore items that do not match the condition
    if (item?.condition && item?.condition?.mode !== options.mode) return <></>


    let border = ''
    // Get state based on path
    //const [state] = useStore(s => path.length === 0 ? s : getNestedValue(s, path))
    const [state] = useStore(s => s)
    const [localState] = useStore(s => getNestedValue(s, path))

    if (item.validation) {
        border = runValidation(item.validation, localState)
    }

    let valueField

    //Determines if it needs to load ModalGroups or if it is embedded
    const modalGroups = processModalGroups(item)

    //Checks if there is a value for the first field in the Block
    const firstFieldValue = path.length === 0
        ? state[modalGroups[0].id]
        //        : getNestedValue(state, [...path, modalGroups[0].id])
        : getNestedValue(state, path)

    if (!firstFieldValue) {
        valueField = t(item.control.placeholder)
    } else {
        const updatedDescriptions = item.descriptions.map(description => {
            //Translating all the labels before processing.
            if (description.type === 'label') {
                return { ...description, text: t(description.text) }
            }
            return description
        })

        // If we have a path, we need to scope the state to the current context
        const contextState = path.length === 0 ? state : getNestedValue(state, path)
        valueField = generateStrings(updatedDescriptions, contextState)
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <FormGroup name='FormGroup' $ctrlid={item.id} $margin={item.fgMargin} key={item.id}>
                {item.control.label ? <FormGroup.Label>{t(item.control.label)}</FormGroup.Label> : <></>}
                <RelativeWrapper>
                    <FormGroup.Control
                        type={item.control.type}
                        placeholder={t(item.control.placeholder)}
                        onClick={toggleModal}
                        value={valueField}
                        {...(validated && item.isRequired && { required: true })}
                        readOnly
                        style={{ paddingRight: '2rem', textOverflow: 'ellipsis', border: border }}
                        data-testid={testId}
                    />
                    <Triangle />
                </RelativeWrapper>
            </FormGroup>
            {showModal &&
                <ModalForm
                    name='ModalForm'
                    mode=''
                    isVisible={showModal}
                    setIsVisible={setShowModal}
                    modalTitle={item.control.placeholder}
                    groups={modalGroups}
                    lineId={lineId}
                    validated={validated}
                    section={section}
                    entity={entity}
                    useStore={useStore}
                    path={path}
                    testId={testId}
                />
            }
        </>
    )
}

export default ModalFormElem
