import styled from "styled-components"

//Import Sub-Components
import { mobileNavBarData } from "../../model/components/mobileNavBarData"
import MobileNavItem from './MobileNavItem'

//Importing Icons to use
import * as AiIcons from 'react-icons/ai'

//Import utilities for the URL
import { useLocation } from 'react-router-dom'
import extractSlug from '../../utils/extractSlug'

import * as BsIcons from "react-icons/bs";

const moreItem = {
    title: 'More',
    icon: <BsIcons.BsThreeDots />,
}


const WrapperDiv = styled.div`
    margin-left: -20px;
    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
`

const PinnedDiv = styled.div`
    position: fixed;
    display: flex;
    padding-inline: 1rem;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.nav.background};
    bottom: 0px;
    width: 100%;
    height: 85px;
    p {
        text-align: center;

    }
`

const DeadSpace = styled.div`
    height:85px;
    display: block;

    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
`

const MobileNavBar = () => {
    const path = useLocation()
    const location = extractSlug(path.pathname).toUpperCase()
    const key = location.toLowerCase()
    const data = mobileNavBarData[key]

    return (
        <WrapperDiv>
                <DeadSpace></DeadSpace>
                    <PinnedDiv>
                        {data.map(
                            (item, i) => {
                                return <MobileNavItem key={item.title} item={item} />
                            }
                        )}
                        <MobileNavItem key='mobile.bar.more' item={moreItem}></MobileNavItem>
                    </PinnedDiv>
        </WrapperDiv>

    )


}

export default MobileNavBar