import { displayViewData } from "../../model/components/displayViewData"

//Form State
// TO REMOVE: import useFormMessage from "../cardView/useFormMessage"
import { useMessage } from "../pageView/Page"
import useFormModal from "../cardView/useFormModal"

import CardViewTitle from "../cardView/CardViewTitle"
import DisplayViewDocument from "./DisplayViewDocument"
import useFormActions from "../cardView/useFormActions"

import MessageBar from "../toast/MessageBar"
import { PopUp } from "../modal/PopUp"
import MobilePanel from "../mobilePanel/MobilePanel"

import styled from "styled-components"
import useFormValidation from "../cardView/useFormValidation"
import { useStore } from "../pageView/Page"


const DisplayViewWrapper = styled.div`
    max-width: ${(props) => props.width};
    margin-bottom: 1rem;
`

const DisplayView = ({ type, options }) => {
    const [state] = useStore(s => s)

    //State used to manage the MessageBar at the Top of the Form
    //const { message, setMessage } = useFormMessage()
    const [message, setMessage] = useMessage((s) => s)

    //Managing the Confirmation Dialog
    const { showModal, setShowModal, modalConfig } = useFormModal()

    //Config to read the data from the Model
    const config = displayViewData[type]

    const { handleFormChange, handleActions } = useFormActions('edit', config)
    const { validated } = useFormValidation

    return (
        <>
            <DisplayViewWrapper name='DisplayView' width={config.width}>
                {message.show && <MessageBar title={message.title} message={message.message} type={message.type} setMessage={setMessage} action={message.action || undefined} handleAction={handleFormChange} />}

                <CardViewTitle config={config} options={options}
                    handleActions={handleActions}
                />
                <br></br>
                <DisplayViewDocument config={config}></DisplayViewDocument>
                <PopUp show={showModal} setShow={setShowModal} config={modalConfig} setAction={handleActions} data={state} />
            </DisplayViewWrapper>
            {
                config.mobilePanel && <MobilePanel validated={validated}
                    config={config.mobilePanel} ></MobilePanel>
            }
        </>
    )
}

export default DisplayView