import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { TextControl } from "../generic/Core";

//Import UI components to be used
import { Form } from '../form/FormComponents';
import { CountryFlag } from "./CountryMenuItem";
import CountryModal_m from "./CountryModal_m";

//setup for language State
import useTranslation from "../../hooks/useTranslation";

import useKeyPress from "../../hooks/useKeyPress";

//Define Styled Components
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputField = styled(TextControl)`
    padding-left: 50px;
`;

const InputFlag = styled(CountryFlag)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 2;
`


const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(90deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent ${({ theme }) => theme.font.color} transparent;
`;

const InputWithIcon = ({ showModal, placeholder, value, valueId, name, isRed, flags, testId }) => {
    const enterPressed = useKeyPress('Enter')

    useEffect(() => {
        const focusedElem = document.activeElement;
        if (enterPressed && focusedElem.name === 'countryName') {
            showModal()
        }
    }, [enterPressed]);

    return (
        <InputWrapper >
            <InputFlag src={flags[valueId]}></InputFlag>
            <InputField onClick={showModal} value={value} name={name} placeholder={placeholder}
            onChange={e => setSearch(e.target.value)}
                {...(isRed && { required: true })}
                data-testid={`${testId}.m`}	
            />
            {!isRed && <InputIcon />}
        </InputWrapper>
    );
};

const SearchContainer = styled.div`
    height: "100%";

    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
    
`

const SearchResults = styled.div`
    position: absolute;
    top: 42px;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
    
    &.visible {
      visibility: visible;
    }
  
    &.invisible {
        visibility: hidden!important;
    }
  `;




const CountryControl_m = ({ data, onSelect, placeholder, valueId, valueName, isRed, flags, testId }) => {
    const [isVisible, setIsVisible] = useState(false);
    const searchContainer = useRef(null);
    const { t } = useTranslation()

    //Hide & Show the Menu
    const showModal = () => { setIsVisible(true) }
    const hideModal = () => setIsVisible(false);

    return (
        <SearchContainer ref={searchContainer}>
            <Form.Group name="Country">
                <InputWithIcon
                    readonly
                    type="text"
                    value={valueName || ''}
                    valueId={valueId}
                    name={'countryName'}
                    showModal={showModal}
                    placeholder={t(placeholder)}
                    isRed={isRed}
                    flags={flags}
                    testId={testId}
                />
            </Form.Group>
            {isVisible && <CountryModal_m isVisible={isVisible} setIsVisible={setIsVisible} data={data} valueName={valueName} onSelect={onSelect} flags={flags} >
            </CountryModal_m>
            }
        </SearchContainer>
    );
};

export default CountryControl_m;