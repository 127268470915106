import { useState } from 'react';
import Toast from '../components/toast/Toast'

function useToast() {
  const [toast, setToast] = useState(null);
  const [title, setTitle] = useState(null);
  const [type, setType] = useState(null);
  const [duration, setDuration] = useState(3000);

  const showToast = (title = ' ', message = ' ', type = 'warning', duration = 3000) => {
    setTitle(title)
    if (message) setToast(message)
    setType(type)
    setDuration(duration)
  };

  const closeToast = () => {
    setToast(null)
    setTitle(null)
    setType(null)
  };

  const ToastComponent = toast && (
    <Toast message={toast} title={title} type={type} duration={duration} onClose={closeToast} />
  );

  return {
    showToast,
    ToastComponent,
  };
}

export default useToast;
