//Setting up subcomponents
import { memo } from 'react'
import FormElem from './FormElem'
import GroupFormElem from './GroupFormElem'
import Display from '../display/Display'

import { Form } from '../form/FormComponents'
import SpinnerButton from '../generic/SpinnerButton'
import ModalFormElem from '../cardView/ModalFormElem'

import useLocale from '../../hooks/useLocale'
import useTranslation from '../../hooks/useTranslation'

const CoreForm = memo(({ section, lineId, expanded = true, validated, dispatch, entity = 'Pop Up', toggleSection = () => { }, useStore, path = [], options }) => {
    const { currentOrgLang } = useLocale()
    const { t } = useTranslation()
    const sectionName = section.meta.name
    let testId = sectionName + '.'
    lineId !== undefined ? testId = `${testId}${lineId + 1}.` : <></>


    //console.log('<CoreForm> path: ', path)

    return (
        <>
            {expanded &&
                section.groups.map(
                    (item, j) => {
                        return (
                            item.type === 'group'
                                ? <GroupFormElem
                                    item={item}
                                    key={`group-${j}`}
                                    lineId={lineId}
                                    validated={validated}
                                    section={section}
                                    entity={entity}
                                    useStore={useStore}
                                    path={path}
                                    options={options}
                                />
                                : item.type === 'modal'
                                    ? <ModalFormElem
                                        lineId={lineId}
                                        item={item}
                                        key={`group-${j}`}
                                        validated={validated}
                                        section={section}
                                        entity={entity}
                                        useStore={useStore}
                                        path={path}
                                        options={options}
                                    />
                                    : <FormElem
                                        section={section}
                                        lang={currentOrgLang}
                                        item={item}
                                        lineId={lineId}
                                        entity={entity}
                                        key={`group-${j}`}
                                        validated={validated}
                                        useStore={useStore}
                                        path={path}
                                        options={options}
                                    />
                        )
                    }
                )}
            <Form.ButtonGroup>
                {expanded &&
                    section.buttons.map(
                        (button, k) => {
                            //Note Submit Button sends the form and it is a special case.
                            return (
                                (button.type === 'submit')
                                    ? <SpinnerButton
                                        $shadow
                                        name='submit'
                                        $variant={button.variant}
                                        key={`btn-${k}`}
                                        type={button.type}
                                        id={button.action}
                                        testId={testId + button.action}
                                    >
                                        {t(button.label)}
                                    </SpinnerButton>
                                    : (button.size === 'icon')
                                        ? <Form.IconButton
                                            key={`btn-${k}`}
                                            icon={button.icon}
                                            $variant='danger'
                                            $size='2.5rem'
                                            testId={testId + button.action}
                                            clickAction={(e) => {
                                                e.preventDefault()
                                                dispatch(button.action)
                                            }}
                                        />
                                        : <Form.Button
                                            $shadow
                                            $variant={button.variant}
                                            key={`btn-${k}`}
                                            type={button.type}
                                            name={button.action}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                dispatch(button.action)
                                            }}
                                            data-testid={testId + button.action}
                                        >
                                            {t(button.label)}
                                        </Form.Button>
                            )
                        }
                    )
                }
            </Form.ButtonGroup>
            {
                !section.meta.type === 'lines' && !expanded &&
                <Display
                    type='root'
                    section={section}
                    clickAction={toggleSection}
                />
            }
        </>
    )
})

export default CoreForm
