import React, { useEffect } from 'react';
import Display from "../display/Display";
import DisplayTotals from "../display/DisplayTotals";
import styled from "styled-components"
import { useStore } from "../pageView/Page";

const PaperSheet = styled.div`
  background-color: ${({ theme }) => theme.page.background} ;
  box-shadow: ${({ theme }) => theme.page.shadow} ;/* Adjust shadow as needed */
  border-radius: 0.5rem; /* Gives a rounded corner effect */
  padding: 20px;
`;


const DisplayLines = ({ section, state }) => {
    return state[section.display.linesArray].map(
        (line, i) => {
            return (
                <React.Fragment key={i}>
                    <Display type='lines' idx={i} key={i} section={section} />
                </React.Fragment>
            )
        }
    )
}



const DisplayViewDocument = ({ config }) => {
    const [state] = useStore((s) => s)
    return (
        <PaperSheet>
            {
                config.sections.map(
                    (section, i) => {
                        return (
                            <React.Fragment key={i}>
                                {

                                    (section.display.type === 'regular') &&
                                    <>
                                        <Display type='root' section={section} key={i} />
                                        <br></br>
                                    </>
                                }
                                {
                                    (section.display.type === 'lines') && 
                                    <>
                                    <DisplayLines section={section} state={state} key={i}  />
                                    <br></br>
                                    </>
                                }
                                {
                                    (section.display.type === 'totals') && <DisplayTotals section={section} key={i} />
                                }
                            </React.Fragment>
                        )
                    }
                )
            }
        </PaperSheet>
    )

}

export default DisplayViewDocument
