import ListView from '../../components/listView/ListView'
import SubList from '../../components/subList/SubList';

import { Icon_Invoice, Icon_Interaction, Icon_People, Icon_Hall, Icon_Boxes, Icon_Lock, Icon_Unlock, Icon_Transaction } from '../../styles/icons';

export const tabViewData = {
    contactTabView: {
        cardViewType: 'contactCard',
        width: '1024px',
        condition: {tab: 'contactPersons', field: 'companyTypeId', value: 'company'}, 
        tabs: [
            {
                id: 'contactPeopleTab',
                key: 'contactPersons',
                label: 'contact.tab.people',
                content: <ListView type='contactPersonList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_People,
                disabled: false,
            },
            {
                id: 'interactionTab',
                key: 'Interactions',
                label: 'contact.tab.interactions',
                content: <ListView type='interactionList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Interaction,
                disabled: false,
            },
            {
                id: 'invoiceTab',
                key: 'Invoices',
                label: 'contact.tab.invoices',
                content: <ListView type='contactInvoiceList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Invoice,
                disabled: false,
            }
        ]
    },
    warehouseTabView: {
        cardViewType: 'warehouseCard',
        width: '1024px',
        condition: {tab: 'halls', field: 'useHalls', value: true},
        tabs: [
            {
                id: 'hallsTab',
                key: 'halls',
                label: 'warehouse.tab.halls',
                content: <ListView type='hallList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Hall,
                disabled: false,
            }
        ]
    },
    productTabView: {
        cardViewType: 'productCard',
        width: '1024px',
        tabs: [
            {
                id: 'itemLocationsTab',
                key: 'itemLocations',
                label: 'product.tab.locations',
                content: <ListView type='itemLocationList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Hall,
                disabled: false,
            }
        ]
    },
    reservationTabView: {
        tabs: [
            {
                id: 'unreservedTab',
                key: 'unreservedStock',
                label: 'To Reserve',
                component: 'ReservationList',  // Reference to component instead of direct component
                icon: Icon_Unlock,
                disabled: false,
            },
            {
                id: 'reservedTab',
                key: 'reservedStock',
                label: 'Reserved',
                component: 'ReservationList',    // Reference to component instead of direct component
                icon: Icon_Lock,
                disabled: false,
            }
        ]
    },
    locationTabView: {
        cardViewType: 'locationCard',
        width: '1024px',
        dynamic: true,
        tabs: [
            {
                id: 'locationItemsTab',
                key: 'locationItems',
                label: 'Products',
                content: 'SubList',
                icon: Icon_Boxes,
                disabled: false,
                config: 'locationItemSubList'
            },
            {
                id: 'locationReservationTab',
                key: 'locationReservations',
                label: 'Reservations',
                content: 'SubList',  
                icon: Icon_Lock,
                disabled: false,
                config: 'locationReservationSubList'
            }
        ]
    },
    paymentAccountTabView: {
        cardViewType: 'paymentAccountCard',
        width: '1024px',
        tabs: [
            {
                id: 'paymentTransactionTab',
                key: 'paymentTransaction',
                label: 'bank.account.form.tab.transactions', 
                content: <ListView type='paymentTransactionList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Transaction,
                disabled: false,
            }
        ]
    },
}