import styled from 'styled-components'

export const TextControl = styled.input`
    ${props => props.autocomplete && `autocomplete: ${props.autocomplete};`}
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${({ theme }) => theme.form.font};
    background: ${({ theme }) => theme.form.textControl.background};
    background-clip: padding-box;
    border: 1px solid ${({ theme }) => theme.form.border};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: ${({ theme }) => theme.form.textControl.boxShadow};

    &:focus {
        border: ${({ theme }) => theme.form.textControl.activeBorder};   
        box-shadow: ${({ theme }) => theme.form.textControl.activeBoxShadow};
        outline: 0;
     }

   &.readonly {
        background-color: ${({ theme }) => theme.form.readOnlyBg};
        color: ${({ theme }) => theme.form.readOnly};
        cursor: not-allowed;
        user-select: none;
    } 

    &:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}        


`

export const Button = styled.button`
    color:${({ $variant, theme, $color }) => $variant === 'transparent' ? theme.font.color : $variant === 'custom' ? $color :theme.btn.font};
    background-color: ${({ $variant, theme, $bg }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : $variant === 'transparent' ? 'none' : $variant === 'custom' ? $bg : '#555'} ;
    border-color: ${({ $variant, theme, $color }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : $variant === 'transparent' ? 'none' : $variant === 'custom' ? $color : '#555'} ;
    
    &:hover {
        color:${({ $variant, theme }) => $variant === 'transparent' ? theme.font.color : theme.btn.font};
        background-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : '#555'} ;
        border-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : '#555'} ;
    }
    
    &:active {
        color:${({ $variant, theme }) => $variant === 'transparent' ? theme.font.color : theme.btn.font};
        background-color: ${({ $variant, theme, $bg }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : $variant === 'custom' ? $bg : '#555'} ;
        border-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : '#555'} ;
    }

    &:focus {
        color:${({ $variant, theme, $color }) => $variant === 'transparent' ? theme.font.color : $variant === 'custom' ? $color :theme.btn.font};
        background-color: ${({ $variant, theme, $bg }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : $variant === 'custom' ? $bg : '#555'} ;
        border-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.active.primary : $variant === 'secondary' ? theme.btn.active.secondary : $variant === 'yellow' ? theme.btn.active.yellow : $variant === 'danger' ? theme.btn.active.danger : $variant === 'transparent' ? 'none' : '#555'} ;
    }

    &:disabled {
        color:${({ $variant, theme }) => $variant === 'transparent' ? theme.font.color : theme.btn.font};
        background-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : '#555'}; 
        border-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : '#555'};
        opacity: 0.65;
    }

    
    box-shadow: ${({ theme, $shadow }) => $shadow ? theme.card.boxShadow : 'none'};
    padding-inline: 0.75rem;
    padding-block: 0.375rem;
    font-size: var(--font-S);
    margin-right: 0.5rem;
    width: ${({ width }) => width ? width : ''};
    font-weight: 400;
    line-height: 1.5;
    border-width: 0px;
    border-radius: 2rem;   
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const Text = styled.p`
    margin-block: 0.25rem;
    width: ${(props) => (props.$variant === 'noMargin') ? '100%' : 'calc(100% - 1rem);'};
    font-size: var(--font-XS);
`

export const Title = styled.h4`
    margin-block: 0.25rem;
    //To avoid conflicting with the collapse Button
    width: ${(props) => (props.$variant === 'noMargin') ? '100%' : 'calc(100% - 1rem);'};
    font-weight:800;
`

export const Spacer = styled.span`
    width: ${(props) => props.width ? props.width : '0.5rem'};
`

export const Separator = styled.div`
    height: 1px;
    border: 1px solid;
    margin-inline: -1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-color: ${({ theme }) => theme.form.border}; ;
`

export const LinkText = styled(Title)`
    color: ${({ theme }) => theme.font.highlight};
    width: calc(100% - 4rem);

    &:hover{
        cursor: pointer;
    }
`

export const EmptyFooter = styled.p`
    height: ${(props) => props.height ? props.height : '1rem'}
`

export const ButtonLine = styled.div`
    padding-top: 1rem;
    display: flex;
    justify-content: end;
`

export const Tag = styled.span`
    padding: 0.5rem;
    min-width: 1.5rem;
    text-align: center;
    font-size: var(--font-XS);
    color:${({ $variant, theme }) => $variant === 'transparent' ? theme.font.color : theme.btn.font};
    background-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : $variant === 'transparent' ? 'none' : '#555'} ;
    border-color: ${({ $variant, theme }) => $variant === 'primary' ? theme.btn.primary : $variant === 'secondary' ? theme.btn.secondary : $variant === 'yellow' ? theme.btn.yellow : $variant === 'danger' ? theme.btn.danger : $variant === 'transparent' ? 'none' : '#555'} ;
    margin: ${({ $margin }) => $margin ? $margin : '0 0 0 1rem'};
    border-radius: 0.5rem;
`

export const P = styled.p`
    margin-block:0rem;
    font-size: var(--font-S);
`
