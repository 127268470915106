import { multiViewData } from "../../model/components/multiViewData";
import { sidePanelData } from "../../model/components/sidePanelData";

//Form State
import useFormValidation from "../cardView/useFormValidation";

//Importing the Subcomponents
import FixedView from "./FixedView";
import ResizableView from "./ResizableView";

const MultiView = ({ type, options, dispatch}) => {
  //Setting up the state for the form.
  const { validated } = useFormValidation()

  let resizeable = true
  if (options?.resizeable) resizeable = options.resizeable

  //Config to read the data from the Model
  const config = multiViewData[type]
  if (!config) return <span>No real config found for {type}</span>
  const sidePanelConfig = sidePanelData[config.sidePanelType]

  return (
    <>
      {
        !resizeable && <FixedView options={options} validated={validated}
          sidePanelConfig={sidePanelConfig} config={config} type={type} dispatch={dispatch} />

      } {resizeable && <ResizableView options={options} validated={validated}
        sidePanelConfig={sidePanelConfig} config={config} dispatch={dispatch} type={type} />}
    </>
  );
};

export default MultiView;
