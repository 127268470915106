import subMultiply from './operations/subMultiply.js'
import headerSumLines from './operations/headerSumLines.js'
import headerSumSubLines from './operations/headerSumSubLines.js'
import subAggregate from './operations/subAggregate.js'
import headerSum from './operations/headerSum.js'
import sumDateByDays from './operations/sumDateByDays.js'
import headerSetValue from './operations/headerSetValue.js'
import subTransfer from './operations/subTransfer.js'
import subCalcPayments from './operations/subCalcPayments.js'
import condiSetValue from './operations/condiSetValue.js'
import serieCalculate from './operations/serieCalculate.js'

function calcFields(data, operation, prevData = {}) {
  const { operationType } = operation
  let result = data

  if (operationType === 'sub.multiply') {
    result = subMultiply(result, operation)
  }

  if (operationType === 'header.sumLines') {
    result = headerSumLines(result, operation)
  }

  if (operationType === 'header.sumSubLines') {
    result = headerSumSubLines(result, operation)
  }

  if (operationType === 'sub.aggregate') {
    result = subAggregate(result, operation)
  }

  if (operationType === 'header.sum') {
    result = headerSum(result, operation)
  }

  if (operationType === 'header.setValue') {
    result = headerSetValue(result, operation)
  }

  if (operationType === 'sub.transfer') {
    result = subTransfer(result, operation, prevData)
  }

  if (operationType === 'condi.setValue') {
    result = condiSetValue(result, operation)
  }

  if (operationType === 'sum.dateByDays') {
    result = sumDateByDays(result, operation, prevData)
  }

  if (operationType === 'sub.calcPayments') {
    result = subCalcPayments(result, operation, prevData)
  }

  if (operationType === 'serie.calculate') {
    result = serieCalculate(result, operation, prevData)
  }

  if (result) return result
  //ALWAYS RETURN AT THE END OF ALL IF STATEMENTS
  return data;
}

export default calcFields
