import { useRef, useEffect } from "react";
import Modal from "../modal/Modal";
import useTranslation from "../../hooks/useTranslation";
import { Button } from "../generic/Core";
import styled from "styled-components";


//Sub Components
import GroupFormElem from "./GroupFormElem";
import FormElem from "./FormElem";
import PdfPager from "../pdf/PdfPager";
import ModalList from "../modal/ModalList";
import MultiSelect from "../multiSelect/MultiSelect";
import TabList from "../multiSelect/TabList";


import { getNestedValue, setNestedValue } from "../../utils/objects/nestedValue"

const Spacer = styled.div`
    height: 20px;
`

const ModalForm = ({
    isVisible,
    setIsVisible,
    modalTitle,
    groups,
    lineId,
    validated,
    section,
    mode = '',
    entity,
    useStore,
    path = [],
    testId
}) => {
    const { t } = useTranslation()
    const sectionName = section?.meta?.name

    const modalPadding = section?.meta?.modalPadding || '1rem'
    // Get state based on path
    const [state, setState] = useStore(s => path.length === 0 ? s : getNestedValue(s, path))

    const focusRef = useRef(null)
    const updateBtnRef = useRef(null)

    useEffect(() => {
        if (focusRef.current) { focusRef.current.focus() }
    }, [])

    //Function that when it detect an enter changes the focus to the close button and clicks on it.
    const handleKeyDown = (e) => {
        //Condition checks if Enter is pressed and if the target is the same as the close Button
        if (e.key === 'Enter' && e.target === updateBtnRef.current) {
            updateBtnRef.current.click()
        }
        if (e.key === 'Escape') {
            setIsVisible(false)
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        setIsVisible(false)

        // Update state at the correct path
        if (path.length === 0) {
            setState({ ...state, savedOn: new Date() })
        } else {
            setState(s => {
                const newState = setNestedValue(s, path, { ...state, savedOn: new Date() })
                return { ...newState, savedOn: new Date() }
            })
        }
    }

    return (
        <Modal setShow={setIsVisible} show={isVisible} mode={mode} className={''} handleKeyDown={handleKeyDown}>
            <Modal.Header setShow={setIsVisible}>
                <h3 style={{ margin: '0px' }}>{t(modalTitle)}</h3>
            </Modal.Header>
            <Modal.Body $padding={modalPadding}>
                {
                    groups.map(
                        (item, j) => {
                            return (
                                item.type === 'group'
                                    ? <GroupFormElem
                                        item={item}
                                        key={`group-${j}`}
                                        lineId={lineId}
                                        validated={validated}
                                        useStore={useStore}
                                        ref={(item.focus) ? focusRef : null}
                                        section={section}
                                        entity={entity}
                                        path={path}
                                    />
                                    : item.type === 'pdf'
                                        ? <PdfPager
                                            state={state}
                                            key={`group-${j}`}
                                            entity={entity}
                                        />
                                        : item.type === 'list'
                                            ? <ModalList
                                                entity={entity}
                                                validated={validated}
                                                useStore={useStore}
                                                section={item.section}
                                                key={`group-${j}`}
                                                lineId={lineId}
                                                path={path}
                                            />
                                            : item.type === 'multiSelect'
                                                ? <MultiSelect
                                                    entity={entity}
                                                    validated={validated}
                                                    useStore={useStore}
                                                    section={item.section}
                                                    key={`group-${j}`}
                                                    lineId={lineId}
                                                    path={path}
                                                />
                                                : item.type === 'tabList'
                                                ? <TabList
                                                    entity={entity}
                                                    validated={validated}
                                                    useStore={useStore}
                                                    section={item.section}
                                                    key={`group-${j}`}
                                                    lineId={lineId}
                                                    path={path}
                                                />
                                                : <FormElem
                                                    item={item}
                                                    key={`group-${j}`}
                                                    validated={validated}
                                                    lineId={lineId}
                                                    ref={(item.focus) ? focusRef : null}
                                                    section={section}
                                                    entity={entity}
                                                    useStore={useStore}
                                                    path={path}
                                                />
                            )
                        }
                    )}
            </Modal.Body>
            <Modal.Footer>
                <Button ref={updateBtnRef} $variant='primary' onClick={handleClick} data-testid={`${testId}.save`}>
                    {t('all.form.button.update')}
                </Button>
            </Modal.Footer>
            {(mode === 'fullscreen') && <Spacer />}
        </Modal>
    )
}

export default ModalForm
