export const dataListData = {
    fonts:
        [
            { id: 'Roboto', name: 'Roboto' },
            { id: 'NunitoSans', name: 'NunitoSans' }, 
            { id: 'Helvetica', name: 'Helvetica' },
            { id: 'Courier', name: 'Courier' },
            { id: 'Urbanist', name: 'Urbanist' },
            { id: 'Times-Roman', name: 'Times New Roman' },
            { id: 'Alegreya', name: 'Alegreya' },
            { id: 'Poppins', name: 'Poppins' },
            { id: 'OpenSans', name: 'OpenSans' },
            { id: 'Raleway', name: 'Raleway' },
        ],
    layouts:
        [
            { id: 'default', name: 'Default' },
            { id: 'modern', name: 'Modern' },
            { id: 'professional', name: 'Professional' },
            { id: 'compact', name: 'Compact' }
        ]
}