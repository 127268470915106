import { Button } from './Core';
import useTranslation from '../../hooks/useTranslation';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const Card = styled.div`
    --card-cap-padding-y: 0.5rem;
    --card-cap-padding-x: 1rem;
    --card-spacer-y: 1rem;
    --card-spacer-x: 1rem;
    --card-height: var(--size-MML);
    --card-border-width: 1px;
    --card-border-color: var(--bs-border-color-translucent);
    --card-border-radius: 0.375rem;
    position: relative;
    display: flex;
    max-width: 16rem;
    flex-direction: column;
    min-width: 0;
    background: ${({ theme }) => theme.card.background};
    flex: 1 1 auto;
    padding: var(--card-spacer-y) var(--card-spacer-x);
    color: var(--card-color);
    height: var(--card-height);
    background-clip: border-box;
    border: var(--card-border-width) solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    word-wrap: break-word;
    box-shadow: ${({ theme }) => theme.card.boxShadow};
    //Square on Mobile 5x5 rem
    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
      width: 5rem;
      height: 5rem;
      max-width: 30%;
    }

    &:hover {
       background: ${({ theme }) => theme.card.active};
    }
`
const CardBody = styled.div`

`

const CardName = styled.div`
    //Reduce font on Mobile for Card Name
    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
    font-size: var(--font-XS);
  }
`


const CardTitle = styled.div`
    display: flex;
    --card-title-spacer-y: 0.5rem;
    margin-bottom: var(--card-title-spacer-y);
    font-size: var(--font-M);

    //Turn the Icon Title Vertical for Mobile
    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

`
const CardText = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: var(--font-XS);
  height: var(--size-M);
  text-overflow: ellipsis;
  overflow: hidden;

  //Remove Card Text on Mobile
  @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
    display: none;
  }


`

const CardIcon = styled.div`
  display: inline ;
  max-height: var(--size-MMS);
  
  svg {
    margin-bottom: 0.1rem;
    margin-right: 0.5rem;
  }

    //Remove Margin Right on Mobile on the SVG inside the CardIcon
    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
      svg {
        margin-right: 0rem;
      }
  }


`

const CardButton = styled(Button)`
  //No Card Button on Mobile
   @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
    display: none;
  }

`
const CardLink = styled(Link)`
   @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {

  }
  
`

Card.Body = CardBody
Card.Title = CardTitle
Card.Text = CardText
Card.Icon = CardIcon
Card.Button = CardButton
Card.Name = CardName
Card.Link = CardLink

function CardTile({ data }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const isMobile = window.matchMedia('(max-width: 568px)').matches;


  return (
    <Card name='Card' data-testid={`card.${data.appId}.whole`}>
      <Card.Link to={data.appUrl} name='Card Link'>
        <Card.Body name='Card Body'>
          <Card.Title name='Card Title'>
            <Card.Icon name='Card Icon'>
              {data.appIcon}
            </Card.Icon>
            <Card.Name name='Card Name'>
              {t(data.appTitle)}
            </Card.Name>
          </Card.Title>
          <Card.Text name='Card Text'>
            {t(data.appDescription)}
          </Card.Text>
          <Card.Button data-testid={`card.${data.appId}`} onClick={(e) => { navigate(data.appUrl) }} $variant="primary" >{t('app.list.button')}</Card.Button>
        </Card.Body>
      </Card.Link>
    </Card>
  );
}

export default CardTile;